<template>
<v-dialog
    class="overflow-visible"
    no-click-animation
    max-width="450"
    persistent
    v-model="dialog"
>
    <close-button @click="closeDialog()" overflow/>

    <v-card class="px-7 py-3">
        <v-card-title class="mb-5 justify-center">
           <v-icon class="mr-1">mdi-store-plus</v-icon> <span class="font-weight-bold">Add to Marketplace</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="marketPlaceForm">
                <v-text-field
                    v-model="data.marketplaceName"
                    label="Plan Name"
                    :rules="[rules.required]"
                    dense
                    outlined
                >
                </v-text-field>
                <v-textarea 
                    v-model="data.marketplaceDesc"
                    label="Short Description"
                    name="shortDesc"
                    :rules="[rules.shortDesc, rules.required]"
                    outlined
                >
                </v-textarea>

                <v-btn
                    @click="validateForm()"
                    color="primary"
                    :loading="status.adding"
                    block
                >
                    Add to Marketplace
                </v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</v-dialog>
  
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
    name: "AddToMarketplace",
    data() {
        return {
            rules,
            dialog: false
        }
    },
    props: {
        plan: Object
    },
    computed: {
        ...mapState({
            data: state => state.marketplace.data,
            status: state => state.marketplace.status
        })
    },
    methods: {
        ...mapActions('marketplace', [
            'addToMarketplace'
        ]),
        closeDialog() {
            this.dialog = false
            this.$refs.marketPlaceForm.reset()
            this.$refs.marketPlaceForm.resetValidation()
        },
        showDialog() {
            this.dialog = true
        },
        validateForm() {
            if (this.$refs.marketPlaceForm.validate()) {
                const task =  Promise.resolve(this.addToMarketplace(this.plan.id))

                task.then(({ error }) => {
                    if (!error) {
                        this.closeDialog()
                    }
                })
            }
        }
    }
}
</script>

<style>

</style>