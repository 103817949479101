<template>
  <v-dialog
    v-model="dialog"
    class="overflow-visible"
    no-click-animation
    max-width="450"
    persistent
  >
    <close-button @click="closeDialog()" overflow/>
    
    <v-card class="px-7 py-3">
      <v-card-title class="mb-5 justify-center">
        <span class="font-weight-bold">{{ data.id ? 'Edit Plan Name' : 'Add Meal Plan' }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="mealPlanForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Name"
            dense
            outlined
          ></v-text-field>

          <v-btn
            @click="validateForm()"
            :loading="status.adding"
            color="primary"
            block
          >
            {{ data.id ? 'Update' : 'Add' }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      rules,
      dialog: false,
    }
  },

  computed: {
    ...mapState({
      data: state => state.mealplans.data,
      status: state => state.mealplans.status,
    })
  },

  methods: {
    ...mapActions('mealplans', [
      'create',
      'update',
      'getPlans',
    ]),

    closeDialog() {
      this.dialog = false
      this.$refs.mealPlanForm.reset()
      this.$refs.mealPlanForm.resetValidation()
    },

    validateForm() {
      if (this.$refs.mealPlanForm.validate()) {
        let task
        
        if (this.data.id) task = Promise.resolve(this.update())
        else task = Promise.resolve(this.create())
        
        task.then(({error, newData}) => {
          if (!error) {
            this.closeDialog()
            this.$emit('addedNewMeal', newData)
          }
        })
      }
    },

    showDialog() {
      this.dialog = true
    }
  }
}

</script>
