<template>
  <v-dialog
    class="overflow-visible"
    no-click-animation
    max-width="1000"
    :value="show"
    persistent
    scrollable
  >
    <close-button @click="$emit('cancel')" overflow />

    <v-card v-if="recipe">
      <v-card-title :class="$classes.cardTitle" >
        <span class="font-weight-bold headline text-center">{{ recipe.name }}</span>
        
        <v-spacer></v-spacer>

        <v-btn 
          v-if="!recipe.privateRecipe && !recipe.customized_by_id && !recipe?.toggleCompany"
          @click="confirmDialog = true"
          :disabled="!this.$store.getters['subscriptions/subscribed']"
          color="info" 
          class="mr-5"
          depressed
        >
          <v-icon left>mdi-pencil</v-icon>
          Customize
        </v-btn>
        <v-btn 
          v-if="!mealPrep"
          @click="startDownload()" 
          :loading="downloading"
          color="primary" 
          :class="hasDownloaded ? 'mr-5' : 'mr-2'"
          depressed
          :disabled="!hasDownloaded && user.role != 'admin'"
          v-show="user.role == 'admin'"
        >
          <v-icon left>mdi-printer</v-icon>
          PDF
        </v-btn>
        <!-- <v-tooltip bottom
            v-if="!hasDownloaded && user.role != 'admin'"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="black"
            dark
            v-bind="attrs"
            v-on="on"
            size="15"
          >
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span>This button will be activated after downloading the Meal Plan</span>
      </v-tooltip> -->
      </v-card-title>
      <v-card-text class="pt-5 pb-7">

        <v-row>
          <v-col cols="12" md="7" lg="7" xl="7">
            <!-- INGREDIENTS -->

            <v-card v-if="recipe.ingredients && recipe.ingredients.length" class="mb-5" flat>
              <v-simple-table class="grey lighten-4" bordered dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="mb-5 subtitle-1 font-weight-bold primary--text">Ingredients</th>
                      <th>Serve Amount</th>
                      <th>Serve Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="recipeIngredient in orderBy(recipe.ingredients, 'order', 'asc')" :key="recipeIngredient.id">
                      <td class="text-capitalize py-2" width="40%">{{ recipeIngredient.food }}</td>
                      <td v-if="recipe.lock_serve" width="30%">{{ parseFloat(recipeIngredient.quantity) }}</td>
                      <td v-else-if="mealPrep">0</td>
                      <td v-else width="30%">
                        {{ parseFloat((recipeIngredient.quantity * ((serves || recipe.numberOfServes) / recipe.numberOfServes)).toFixed(2)) }}</td>
                      <td width="30%">{{ recipeIngredient.portion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <p v-if="mealPrep">This meal has been meal prepped. Click <a @click="$emit('showOriginalRecipe', { serves: meal.originData.serves, recipe, day: meal.originData.day, mealTime: meal.originData.meal, plan: cell.plan, meal: meal.originData.id })" class="text-decoration-underline">here</a> to see the original recipe</p>

            <div v-else>
              <span v-if="recipe.lock_serve">
                 <v-alert
                  dense
                  text
                  type="warning"
                >
                <strong>WARNING</strong>: Original serve quantities shown only due to ingredients that don't divide well
                </v-alert>
                <p class="mb-5 font-weight-bold">
                  Makes {{ recipe.numberOfServes  }} Serves ({{ recipe.servingValues.weight | numberFormat }}g per serve)
                </p>
              </span>
              <p v-else-if="recipe.servingValues" class="mb-5 font-weight-bold">
                Makes {{ serves || recipe.numberOfServes }} Serves ({{ recipe.servingValues.weight | numberFormat }}g per serve)
              </p>
            </div>

            <!-- MEAL TIME -->
            <div v-if="recipe.mealTime && recipe.mealTime.length" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                Meal Time
              </h5>

              <p>{{ recipe.mealTime.join(', ') }}</p>
            </div>

            <!-- VIDEO -->

            <div v-if="recipe.video" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                Video
              </h5>

              <a :href="recipe.video" target="_blank">{{ recipe.video }}</a>
            </div>

            <!-- DESCRIPTION -->
            <div v-if="recipe.description" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                Description
              </h5>

              <p class="pre-wrap" v-html="linkify(recipe.description)"></p>
            </div>

            <!-- METHODS -->
            <div v-if="recipe.methods && recipe.methods.length" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                Methods
              </h5>

              <div v-if="mealPrep">This is a pre-prepared meal for the purpose of leftovers or meal prepping. Please refer to the original recipe for quantities and cooking method.</div>

              <ol v-else>
                <li
                  v-for="(method, i) in recipe.methods"
                  :key="i"
                  class="mb-1 pre-wrap"
                >
                  {{ method }}
                </li>
              </ol>
            </div>

            <!-- ANALYSIS SUMMARY -->
            <div>
              <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                Analysis Summary
              </h5>

              <v-card flat>
                <v-simple-table class="grey lighten-4" id="nutrient-table" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="33%"></th>
                        <th width="33%">Quantity Per Serving</th>
                        <th width="33%">Quantity Per 100g</th>
                      </tr>
                    </thead>
                    <tbody v-if="recipe.servingValues">
                      <tr>
                        <td>Weight (g)</td>
                        <td>{{ recipe.servingValues.weight | amount | numberFormat }}g</td>
                        <td>100.00g</td>
                      </tr>
                      <tr>
                        <td>Energy {{ user.energyUnit == 'cal' ? '(Cal)' : '(kJ)' }}</td>
                        <td v-if="user.energyUnit == 'cal'">{{ recipe.servingValues.energy | kjToCal | amount | numberFormat }}Cal</td>
                        <td v-else>{{ recipe.servingValues.energy | amount | numberFormat }}kJ</td>
                        <td v-if="user.energyUnit == 'cal'">{{ recipe.servingValuesPerHundredGram.energy | kjToCal }}Cal</td>
                        <td v-else>{{ recipe.servingValuesPerHundredGram.energy }}kJ</td>
                      </tr>
                      <tr>
                        <td>Protein (g)</td>
                        <td>{{ recipe.servingValues.protein | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.protein }}g</td>
                      </tr>
                      <tr>
                        <td>Fat (g)</td>
                        <td>{{ recipe.servingValues.fat | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.fat }}g</td>
                      </tr>
                      <tr>
                        <td>Carbohydrate (g)</td>
                        <td>{{ recipe.servingValues.carbohydrate | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.carbohydrate }}g</td>
                      </tr>
                      <tr>
                        <td>Sugar (g)</td>
                        <td>{{ recipe.servingValues.sugar  | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.sugar }}g</td>
                      </tr>
                      <tr>
                        <td>Fibre (g)</td>
                        <td>{{ recipe.servingValues.fibre  | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.fibre }}g</td>
                      </tr>
                      <tr>
                        <td>Sodium (mg)</td>
                        <td>{{ recipe.servingValues.sodium  | amount | numberFormat }}mg</td>
                        <td>{{ recipe.servingValuesPerHundredGram.sodium }}mg</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" md="5" lg="5" xl="5">
            <RecipeImage :recipe="recipe" @url="setImageUrl($event)" size="medium" />

            <!-- RECIPE RATING -->
            <recipe-rating :recipe="recipe" global />

            <div class="mt-5">
              <div class="font-weight-bold mb-2 primary--text">Meal Tags</div>

              <span
                v-for="(tag, i) in tagsConf"
                :key="i"
              >
                <v-chip
                  label
                  small
                  class="mr-1 mb-1"
                  v-if="$store.getters['tags/checkIfUnpublish'](tag)"
                >{{ $store.getters['tags/getName'](tag) }}</v-chip>
              </span>
            </div>
            
            <div class="mt-3">
              <div class="font-weight-bold mb-2 primary--text">Nutrition Tags</div>

              <span
                v-for="(tag, i) in recipe.nutritionTags"
                :key="i"
              >
                <v-chip
                  label
                  small
                  class="mr-1 mb-1"
                  v-if="$store.getters['nutritiontags/checkIfUnpublish'](tag)"
                >{{ $store.getters['nutritiontags/tagData'](tag).name }}</v-chip>
              </span>
            </div>

            <v-card class="my-5" flat color="grey lighten-4">
              <v-card-text>
                <div class="d-flex align-center" v-if="!recipe?.toggleCompany">
                  <v-avatar class="mr-3" size="40">
                    <UserPhoto :id="recipe.user" :size="50" photoSize="thumb" />
                  </v-avatar>
                  <div>
                    <div class="font-weight-bold">{{ $store.getters['members/getUser'](recipe.user) }} </div>
                    <div class="caption">
                      Created {{ recipe.createdAt | formatDate }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center" v-else>
                  <!-- <v-avatar class="mr-3" size="40">
                    <UserPhoto :id="recipe.user" :size="50" photoSize="thumb" />
                  </v-avatar> -->
                  <div>
                    <div class="caption">Ready made meal by:</div>
                    <div class="font-weight-bold mb-3">{{ recipe?.companyName }} </div>
                    <v-btn
                      x-small
                      depressed
                      color="primary"
                      @click="buyProduct(recipe?.companyLink)"
                    >
                    buy now
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <report-dialog ref="reportDialog" :recipe="recipe" />

            <!-- RATING FORM -->
            <rating-form :recipe="recipe" :rate="rate" class="mb-5" />

            <!-- IF STATUS IS PENDING -->
            <div v-if="recipe.status == 'pending'">
              <action-list @approved="$emit('cancel')" :recipe="recipe" />
            </div>

            <!-- CONFIRM COPY PHOTO -->
            <v-dialog
              :value="confirmDialog"
              no-click-animation
              max-width="400"
              persistent
            >
              <v-card>
                <v-card-title>
                  <span class="font-weight-bold">Please Confirm</span>
                </v-card-title>
                <v-card-text>
                  Do you want to use the original photo of this recipe? Click 'NO' to upload your own image
                  <div class="mt-5">
                    <v-btn
                      @click="goToCustomizePage({ recipe, cell, meal, photo: true })"
                      color="accent"
                      class="mr-2"
                      depressed
                    >Yes</v-btn>

                    <v-btn
                      depressed
                      @click="goToCustomizePage({ recipe, cell, meal, photo: false })"
                    >No</v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    
  </v-dialog>
</template>

<script>
// PLUGINS
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'

// COMPONENTS
import ReportDialog from './ReportDialog.vue'
import UserPhoto from '@/components/UserPhoto'
import RatingForm from '@/components/RatingForm'
import RecipeImage from '@/components/RecipeImage'
import RecipeRating from '@/components/RecipeRating'
import ActionList from '@/views/recipe/components/ActionList'

// MIXINS
import downloadRecipe from '@/mixins/download-recipe-pdf'

export default {
  name: 'RecipeCard',

  mixins: [downloadRecipe],

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean,
    rate: Boolean,
    recipe: [Object, null],
    serves: Number,
    mealPrep: Boolean,
    cell: Object,
    meal: Object,
    hasDownloaded: Boolean,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    UserPhoto,
    RatingForm,
    ActionList,
    RecipeImage,
    RecipeRating,
    ReportDialog,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      requesting: false,
      measurements: null,
      downloading: false,
      image: '/placeholder.svg',
      confirmDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      recipeIngredients: state => state.recipe.ingredients,
    }),

    gramPerServe: function () {
      return Math.round(this.getTotalWeight)
    },

    tagsConf: function () {
      if (this.recipe && this.recipe.tags) {
        return this.recipe.tags.filter(tag => {
          return this.$store.getters['tags/getName'](tag)
        })
      }
      else return []
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('recipe', ['customizeRecipe']),
    setImageUrl(e) {
      this.image = e
    },

    startDownload() {
      this.downloading = true

      Promise.resolve(this.downloadPDF())
      .then(() => {
        this.downloading = false
      })
    },

    addFixedDecimals(val) {
      if(val % 1 != 0) 
        return this.$options.filters.amount(val)

      return val
    },

    goToCustomizePage({recipe, cell, meal, photo}) {
      this.customizeRecipe({ recipe, cell, meal, photo })
    },
    linkify(text) {
      // Regular expression to match URLs
      const urlRegex = /(\b(?:https?|ftp):\/\/|www\.)\S+\b/gi;
      
      // Replace URLs with hyperlink tags
      return text.replace(urlRegex, function(url) {
        // Add http:// prefix if the URL doesn't have one
        if (!url.match(/^https?:\/\//i)) {
          url = 'http://' + url;
        }
        return '<a href="' + url + '">' + url + '</a>';
      });
    },
    buyProduct(link) {
      setTimeout(() => {
        window.open(link, '_blank')
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch('tags/getTags')    
    if (!this.$store.state.nutritiontags.status.firstLoad) this.$store.dispatch('nutritiontags/getTags')
  }

}

</script>

<style>

#nutrient-table tr th:not(:first-child),
#nutrient-table tr td:not(:first-child) {
  text-align: right;
}

</style>
