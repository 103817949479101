<template>
  <div class="d-flex gap-20" :style="{ height: '150px' }">
    <v-card outlined>
      <v-responsive width="150" :aspect-ratio="1/1">
        <v-hover v-slot:default="{ hover }">
          <v-card-text class="d-flex align-center justify-center fill-height">
            <a v-if="!user.customLogo" class="caption" @click="showDialog()" small text>Upload Your Logo</a>
            <div v-else>
              <v-img v-if="user?.isStudent"  :src="user.customLogoUrl" width="100" contain/>
              <v-img v-else :src="url" width="100" contain/>
            </div>

            <v-overlay v-if="hover && user.customLogo" absolute>
              <v-btn @click="deleteDialog = true" icon outlined>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-overlay>
          </v-card-text>
        </v-hover>
      </v-responsive>
    </v-card>

    <v-card :color="user.brandColor || 'primary'" outlined>
      <v-responsive width="150" :aspect-ratio="1/1">
        <v-card-text class="d-flex fill-height">
          <div class="mt-auto">
            <div class="mb-2 white--text">Brand Colour Hex Code</div>
            <v-text-field
              v-model.trim="user.brandColor"
              @keydown="updateBrandColor()"
              :style="{ fontSize: '13px' }"
              placeholder="e.g. #97c81f"
              class="white mt-auto"
              hide-details
              outlined
              dense
            />
          </div>
        </v-card-text>
      </v-responsive>
      <v-responsive :style="{lineHeight: '0.8'}" width="200">
        <small v-if="hexCodeError" :style="{fontSize: '11px', color: 'red'}">Please ensure your hex code has the "#' symbol first, plus 6 characters to match your hex code. If you are unsure of your brand colour hex code, please upload your logo to this website to find out <a href="https://imagecolorpicker.com/en" target="_blank">https://imagecolorpicker.com/en</a></small>
      </v-responsive>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog"
      max-width="550"
    >
      <v-card>
        <v-card-title class="font-weight-bold" >
          Select a file
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <p v-if="status.uploading">Please wait, updating is in progress...</p>
            <v-file-input
              :rules="[rules.required, (value) => value.size < 2000000 || 'Image should be less than 2mb']"
              placeholder="Only accepts .png and .jpg files"
              accept="image/png, image/jpeg"
              prepend-icon=""
              v-model="file"
              show-size
              outlined
              dense
            />
          </v-form>

          <v-btn 
            :loading="status.uploading"
            @click="startUpload()"
            :disabled="!file" 
            color="primary" 
            block
          >Start Upload</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CONFIRM DELETE -->
    <confirm-delete 
      :showDialog="deleteDialog"
      message="Delete your logo?"
      :deleting="status.deleting"
      @confirmed="deleteConfirmed()"
      @cancel="deleteDialog = false"
    />

    <v-dialog
        v-model="showEbookUpdate"
        max-width="290"
    >
        <v-card>
        <v-card-title class="headline">
            Notice
        </v-card-title>
    
        <v-card-text>
            <p>
              Your logo or brand colour has been changed. Please allow 1-2 minutes for any eBook links to be updated with the new logo or brand colour
            </p>
        </v-card-text>
    
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            text
            @click="showEbookUpdate = false"
            > 
            Okay
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'
import { debounce } from 'lodash'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      url: null,
      file: null,
      userCopy: {},
      dialog: false,
      hexCodeError: false,
      deleteDialog: false,
      showEbookUpdate: false
    }
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    plans: Array
  },
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.file_upload.status,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': {
      handler(user) {
        if (user && user.customLogo) {
          this.getLogoUrl()
        }

        if (user && !Object.keys(this.userCopy).length)
          this.userCopy = Object.assign({}, user)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('mealplans', ['addGeneratedLink', 'addGeneratedMealplanLink']),
    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.$store.commit('file_upload/updateStatus', { uploading: false })
    },

    startUpload() {
      if (this.$refs.form.validate()) {
        Promise.resolve(
          this.$store.dispatch('file_upload/uploadFile', {
            file: this.file,
            folder: 'custom_logos'
          })
        )
        .then((file) => {
          Promise.resolve(
            this.$store.dispatch('user/updateUserField', {
              field: 'customLogo',
              value: file.file,
              silent: true
            })
          )
          .then(() => {
            this.getLogoUrl()
          })
          
        })
      }
    },

    getLogoUrl() {
      firebase.storage()
      .ref(`custom_logos/${this.user.customLogo}`)
      .getDownloadURL()
      .then((url) => {
        this.url = url
        
        this.$store.dispatch('user/updateUserField', {
          field: 'customLogoUrl',
          value: url,
          silent: true
        })

        // let promises = []

        // this.plans.forEach(plan => {
        //   if(plan?.description) {
        //     const details = {
        //       ...plan?.linkDetails,
        //       user: {
        //         ...plan?.linkDetails?.user,
        //         customLogoUrl: url,
        //         customLogo: url,
        //       }
        //     }

        //     promises.push(this.addGeneratedLink({ plan, details, silent: true }))
        //   }
        //   if(plan?.mealplanDescription) {
        //     const details = {
        //       ...plan?.linkDetails,
        //       user: {
        //         ...plan?.linkDetails?.user,
        //         customLogoUrl: url,
        //         customLogo: url,
        //       }
        //     }

        //     promises.push(this.addGeneratedMealplanLink({ plan, details, silent: true }))
        //   }
        // })
        // this.$store.dispatch('showSuccess', 'File uploaded')

        // Promise.all(promises).then(() => {
        //   this.$store.dispatch('showSuccess', 'Ebook links has been updated')
        // })

        this.closeDialog()
        this.showEbookUpdate = true

      })
      .catch(error => {
        console.log(error)
      })
    },

    deleteConfirmed() {
      Promise.resolve(
        this.$store.dispatch('file_upload/deleteFile', {
          folder: 'custom_logos',
          file: this.user.customLogo
        })
      )
      .finally(() => {
        this.$store.dispatch('user/updateUserField', {
          field: 'customLogo',
          value: null,
          silent: true
        })
        
        this.$store.dispatch('user/updateUserField', {
          field: 'customLogoUrl',
          value: null,
          silent: true
        })

        this.deleteDialog = false
        this.url = null
      })
    },

    updateBrandColor: debounce(function () {
      let reg=/^#([0-9a-f]{3}){1,2}$/i;

      if(!this.user.brandColor || reg.test(this.user.brandColor)) {
        this.hexCodeError = false
        if (this.userCopy.brandColor !== this.user.brandColor) {
          Promise.resolve(
            this.$store.dispatch('user/updateUserField', {
              field: 'brandColor',
              value: this.user.brandColor,
              message: 'Brand color updated'
            })
          )
          .then(() => {
            this.userCopy.brandColor = this.user.brandColor
            this.showEbookUpdate = true

            // this.plans.forEach(plan => {
            //   if(plan?.description) {
            //     const details = {
            //       ...plan?.linkDetails,
            //       user: {
            //         ...plan?.linkDetails?.user,
            //         brandColor: this.user.brandColor
            //       }
            //     }

            //     this.addGeneratedLink({ plan, details, silent: true })
            //   }
            //   if(plan?.mealplanDescription) {
            //     const details = {
            //       ...plan?.linkDetails,
            //       user: {
            //         ...plan?.linkDetails?.user,
            //         brandColor: this.user.brandColor
            //       }
            //     }

            //     this.addGeneratedMealplanLink({ plan, details, silent: true })
            //   }
            // })
          })
        }
      } else {
        this.hexCodeError = true
        this.$store.dispatch('showError', 'Invalid Hex code. Please try again')
      }
    }, 1000)
  },
}
</script>