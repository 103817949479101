<template>
  <v-card class="box-shadow" outlined>
    <v-card-title :class="$classes.cardTitle">
      <span class="font-weight-bold">Recent Members</span>

      <v-btn :to="{ name: 'Members' }" color="primary" depressed small>View All</v-btn>
    </v-card-title>

    <v-card-text v-if="loading">
      <preloader />
    </v-card-text>

    <v-card-text v-if="!loading && !members.length">
      <v-alert type="info" color="grey" text class="mt-5 mb-0">
        No recent members
      </v-alert>
    </v-card-text>

    <v-simple-table v-if="!loading && members.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Name</th>
            <th>Member Since</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in members" :key="member.id">
            <td>
              <UserPhoto :id="member.id" photoSize="thumb" :size="35" class="mr-2" />
              {{ member.firstName }} {{ member.lastName }}
            </td>
            <td class="caption grey--text" width="150">{{ member.registeredAt | formatDate }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import db from '@/firebase/init'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      members: [],
      loading: true,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getRecentMembers() {
      db.collection('users')
      .where('role', '==', 'member')
      .orderBy('registeredAt', 'desc')
      .limit(5).get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(member => {
            let data = this.$formatData(member)
            this.members.push(data)
            this.loading = false
          })
        }
        else {
          this.members = []
          this.loading = false
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getRecentMembers()
  }
}
</script>
