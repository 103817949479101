<template>
  <div>
      <section class="pdf-content" slot="pdf-content">
        <!-- PDF Content Here -->
        <div class="page page-3">
          <div class="watermark" :style="getBackgroundImg()" />

          <div :style="getFontColor()" class="section section-1 plan-name">
            <!-- <div class="logo" :style="getBackgroundImg(true)"></div> -->
            <!-- <img :src="user.customLogoUrl ? user.customLogoUrl: 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fthumb_mealzee_pdf.jpg?alt=media&token=5d591a92-cec3-4646-968d-f104ddcf3d9d'" alt="logo"> -->
            <div class="offset"></div>
            <h1>{{ plan.name }}</h1>
            <div
              v-show="$store.getters['subscriptions/isOnLow']"
              class="text-caption"
              style="color: #000"
            >
              Powered by Mealzee
            </div>
            <!-- <img v-show="$store.getters['subscriptions/isOnLow']" src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fpowered_by.png?alt=media&token=53f85656-97eb-49ba-9fe1-3389861b9b6b" alt="powered by mealzee" class="recipe-img" /> -->
            <img v-show="plan?.requestStatus == 'approved'" src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2FDIETITIAN%20APPROVED%20(TRANSPARENT).png?alt=media&token=0e14d93b-7320-4683-a408-8015e9b3d346" alt="Approval Seal" class="recipe-img" />
            <div />
          </div>
          <div class="section section-2 my-4" >
            <div class="box-container">
              <div v-for="(day, i) in days" class="box box-1" :key="i">
                {{ day }}
              </div>
            </div>
            <div v-for="(meal, j) in mainMeals" class="box-container" :key="j">
              <div
                v-for="(day, i) in days"
                class="box box-2"
                :style="getBoxHeight()"
                :key="i"
              >
                <div class="first-box" v-if="i === 0">
                  {{ meal == 'desert' ? 'dessert' : meal }}
                </div>
                <div v-else class="recipe">
                  <img
                    v-if="getRecipeImg(meal, i) === 'none'"
                    src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fno_image.png?alt=media&token=9cd541b8-ce97-4b99-b8d7-905b5e198b5f"
                    alt="mealzee logo"
                    class="recipe-img logo"
                  />
                  <img
                    v-else
                    :src="getRecipeImg(meal, i)"
                    alt="recipe image"
                    class="recipe-img"
                  />
                  <div :style="getBgColor()" class="recipe-name">
                    {{ getRecipeName(meal, i) | trunc }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="goal.snacks" class="box-container-snack">
              <div
                class="box-container"
                v-for="(snack, j) in goal.snacks"
                :key="j"
              >
                <div
                  class="box box-2"
                  v-for="(day, i) in days"
                  :key="i"
                  :style="getBoxHeight()"
                >
                  <div class="first-box" v-if="i === 0">Snacks {{ j + 1 }}</div>
                  <div class="recipe" v-else>
                    <img
                      v-if="getRecipeImg(`snack${j + 1}`, i) === 'none'"
                      src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fno_image.png?alt=media&token=9cd541b8-ce97-4b99-b8d7-905b5e198b5f"
                      alt="mealzee logo"
                      class="recipe-img logo"
                    />

                    <img
                      v-else
                      :src="getRecipeImg(`snack${j + 1}`, i)"
                      alt="recipe image"
                      class="recipe-img"
                    />
                    <div :style="getBgColor()" class="recipe-name">
                      {{ getRecipeName(`snack${j + 1}`, i) | trunc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            v-if="hideEnergy === false"
            class="section section-3"
            :style="getBgColor()"
          >
            <a v-if="user.facebook" :href="user.facebook" target="_blank">
              <v-icon large color="black"> mdi-facebook </v-icon>
            </a>
            <v-icon large color="black" v-else> mdi-facebook </v-icon>
            <a v-if="user.website" :href="user.website" target="_blank">
              <v-icon large color="black"> mdi-web </v-icon>
            </a>
            <v-icon large color="black" v-else> mdi-web </v-icon>
            <a v-if="user.instagram" :href="user.instagram" target="_blank">
              <v-icon large color="black"> mdi-instagram </v-icon>
            </a>
            <v-icon large color="black" v-else> mdi-instagram </v-icon>
          </div> -->
        </div>
        <div v-if="hideEnergy === false" class="page page-4">
          <div class="watermark" :style="getBackgroundImg()" />
          <div :style="getFontColor()" class="section section-1 page-4">
            <!-- <div class="logo" :style="getBackgroundImg(true)"></div> -->
            <div class="offset"></div>
            <h1>Nutrition Panel</h1>
            <div />
          </div>
          <div class="section section-2">
            <div class="box-container">
              <div class="box box-1">Day</div>
              <div class="box box-1">Weight (g)</div>
              <div class="box box-1">
                Energy {{ user.energyUnit == 'cal' ? '(Cal)' : '(kJ)' }}
              </div>
              <div class="box box-1">Protein (g)</div>
              <div class="box box-1">Fat (g)</div>
              <div class="box box-1">Carbohydrate (g)</div>
              <div class="box box-1">Sugar (g)</div>
              <div class="box box-1">Fibre (g)</div>
              <div class="box box-1">Sodium (mg)</div>
            </div>
            <div
              v-for="(day, i) in [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
                'Average per Day',
              ]"
              class="box-container"
              :key="i"
            >
              <div class="box box-2 first-box">
                {{ day }}
              </div>
              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'weight') | amount | numberFormat
                }}
              </div>

              <div v-if="user.energyUnit == 'cal'" class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'energy')
                    | kjToCal
                    | amount
                    | numberFormat
                }}
              </div>

              <div v-else class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'energy') | amount | numberFormat
                }}
              </div>

              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'protein')
                    | amount
                    | numberFormat
                }}
              </div>

              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'fat') | amount | numberFormat
                }}
              </div>

              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'carbohydrate')
                    | amount
                    | numberFormat
                }}
              </div>

              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'sugar') | amount | numberFormat
                }}
              </div>

              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'fibre') | amount | numberFormat
                }}
              </div>

              <div class="box box-2">
                {{
                  getTotalNutrientValue(i + 1, 'sodium') | amount | numberFormat
                }}
              </div>
            </div>
            <div class="box-container">
              <div class="box box-3 first-box">Macros</div>
              <div class="box box-3">
                <span class="header">Protein</span>
                <span class="desc">
                  {{
                    ((getTotalNutrientValue(8, 'protein') * 16.7) /
                      getTotalNutrientValue(8, 'energy'))
                      | percent
                  }}
                </span>
              </div>
              <div class="box box-3">
                <span class="header">Fat</span>
                <span class="desc">
                  {{
                    ((getTotalNutrientValue(8, 'fat') * 37.7) /
                      getTotalNutrientValue(8, 'energy'))
                      | percent
                  }}
                </span>
              </div>
              <div class="box box-3">
                <span class="header">Carb</span>
                <span class="desc">
                  {{
                    ((getTotalNutrientValue(8, 'carbohydrate') * 16.7) /
                      getTotalNutrientValue(8, 'energy'))
                      | percent
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { truncate, sumBy, uniqBy, orderBy } from 'lodash';
import downloadRecipe from '../../../mixins/download-recipe-pdf';

export default {
  name: 'DownloadMealplan',

  /*------------------------------------------------------------------------------
   * MIXINS
   *----------------------------------------------------------------------------*/
  mixins: [downloadRecipe],

  data() {
    return {
      error: null,
      showBtn: false,
      days: [
        '',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      noRecipe: [
        'no dinner',
        'no breakfast',
        'no lunch',
        'no dessert',
        'no snacks',
      ],
      noSnacks: ['snack1', 'snack2', 'snack3', 'snack4'],
      orderBy,
      serves: 1,
      hideEnergy: false,
      options: {
        margin: 0,

        filename: '',

        image: {
          type: 'jpeg',
          quality: 1,
        },

        enableLinks: true,

        html2canvas: {
          scale: 1,
          useCORS: true,
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'landscape',
        },
      },
    };
  },
  computed: {
    recipes: function () {
      let r = this.rcps || [];
      let m = this.myRecipes || [];

      return [...r, ...m];
    },
    confRecipes: function () {
      return uniqBy(this.recipes, (recipe) => recipe.name);
    },
    numberOfTimes: function () {
      return this.mainMeals.length + this.goal.snacks;
    },
  },
  props: {
    user: Object,
    mainMeals: Array,
    meals: Array,
    goal: Object,
    rcps: Array,
    myRecipes: Array,
    plan: Object,
    name: String,
  },
  methods: {
    ...mapActions('mealplans', ['getPlanToDownload']),
    downloadPDF() {
      this.status.push('Downloading meal plan...');

      Promise.resolve(this.downloadMealPlanPdf()).finally(() => {
        this.status.push('Meal plan downloaded');

        setTimeout(() => {
          this.showBtn = true;
        }, 2500);
      });
    },
    getRecipeName(meal, day) {
      let name;

      name = `no ${meal}`;

      if (this.noSnacks.includes(meal)) name = 'no snacks';
      if (meal == 'desert') name = 'no dessert';

      let hasMeal = this.meals.find((m) => {
        return m.meal == meal && m.day == day;
      });

      if (hasMeal) {
        let recipe;
        recipe = this.recipes.find((r) => r.id == hasMeal.recipe);
        if (recipe) name = recipe.name;
      }

      return name;
    },

    getRecipeImg(meal, day) {
      let img = 'none';

      let hasMeal = this.meals.find((m) => {
        return m.meal == meal && m.day == day;
      });

      if (hasMeal) {
        let recipe = this.recipes.find((r) => r.id == hasMeal.recipe);
        if (recipe?.OriginalPhotoUrl) img = recipe.OriginalPhotoUrl;
        if (recipe?.mediumPhotoUrl) img = recipe.mediumPhotoUrl;
        if (recipe?.thumbPhotoUrl) img = recipe.thumbPhotoUrl;
      }

      return img;
    },

    getRecipeID(name) {
      let recipe = this.recipes.find((r) => r.name == name);
      return recipe ? recipe.id : {};
    },

    getMealID(time, day) {
      if (time.toLowerCase().split(' ')[0] == 'snack')
        time = time.split(' ').join('');

      let meal = this.meals.find(
        (meal) => meal.day === day && meal.meal === time.toLowerCase()
      );

      return meal.id;
    },

    generateReport() {
      this.error = null;
      this.$refs.html2Pdf.generatePdf();
    },

    getTotalNutrientValue(day, type) {
      var total = 0;
      var meals = this.meals.filter(
        (m) => (m.day == day || day >= 8) && m.recipe !== 'no meal'
      );
      var recipeIds = meals.map((m) => m.recipe);
      var recipes = [];

      if (this.recipes.length) {
        recipeIds.forEach((id) => {
          let rec = this.recipes.find((r) => r.id == id);
          if (rec) recipes.push(rec);
        });
      }

      if (recipes.length) {
        total = sumBy(recipes, (r) => r.servingValues[type]);
      }

      var countMeals = uniqBy(meals, (meal) => meal.day);

      return Math.round(day >= 8 ? total / countMeals.length : total);
    },

    beforeDownload() {
      this.$emit('beforeDownload');
    },
    startPagination() {
      this.$emit('downloading');
    },

    hasDownloaded() {
      this.$emit('downloadFinish');
    },

    getBoxHeight() {
      if (this.numberOfTimes === 8) return { height: '60px' };

      if (this.numberOfTimes === 7) return { height: '70px' };

      if (this.numberOfTimes === 6) return { height: '80px' };

      return { height: '90px' };
    },

    getBgColor() {
      if (this.user?.brandColor) {
        return { background: this.user?.brandColor };
      } else {
        return { background: '#97c81f' };
      }
    },

    getFontColor() {
      if (this.user?.brandColor) {
        return { color: this.user?.brandColor };
      } else {
        return { color: '#97c81f' };
      }
    },

    getBackgroundImg(logo = false) {
      if (this.user?.isStudent && !logo) {
        return {
          ['background-image']: `url('https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fsample_only.png?alt=media&token=4c7ab280-fe4e-4666-9804-11f915d93d09')`,
        };
      } else {
        if (this.user?.customLogoUrl)
          return { ['background-image']: `url(${this.user?.customLogoUrl})` };
        else if (this.user.customLogo)
          return { ['background-image']: `url(${this.user?.customLogo})` };
        else
          return {
            ['background-image']: `url('https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fmealzee_pdf.png?alt=media&token=b078269d-186b-4249-b3c1-b15b50ad041e')`,
          };
      }
    },
    addFixedDecimals(val) {
      if (val % 1 != 0) return this.$options.filters.amount(val);

      return val;
    },
  },

  filters: {
    trunc: function (val) {
      if (!val) return '';

      return truncate(val, { length: 17 });
    },
  },
  created() {
    this.options.filename = this.name;
    this.hideEnergy = this.plan?.hideEnergy ? true : false;
    setTimeout(() => {
      this.generateReport();
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
$primary: #97c81f;
$black: rgba(0, 0, 0, 0.87);

* {
  box-sizing: border-box;
  font-family: proxima-nova, sans-serif !important;
}

.toggleDownload {
  color: #33a2f4;
  text-decoration: underline;
  cursor: pointer;
  font: 12px;
}

section.pdf-content {
  height: 100%;
}

.page {
//   height: 762px;
//   padding-top: 0.5rem;
  height: 100%;
  position: relative;

  .watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: auto;
    background-repeat: space repeat;
    opacity: 0.1;
    z-index: -1;
  }

  &.page-3,
  &.page-4 {
    display: grid;
    grid-template-rows: 10% 90% ;
    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.section-1 {
        width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 20% 60% 20%;
        align-content: center;
        text-align: center;
        padding: 0 4rem;
        &.plan-name {
          margin-top: 0.8rem;
          margin-bottom: 0.8rem;

          line-height: 0.8;
        }

        .logo {
          text-align: center;
          background-size: contain;
          background-position: 50%;
          background-repeat: no-repeat;
          max-height: 90px;
          height: 100%;
          min-height: 90px;
          width: 100%;
          max-width: 200px;

          position: absolute;
          top: 50%;
          left: 15%;
          transform: translate(-50%, -50%);
        }

        img {
          height: auto;
          max-height: 90px;
          max-width: 120px;
          width: 100%;
          object-fit: contain;
          object-position: 50% 50%;
        }
        h1 {
          justify-self: center;
          font-size: 50px;
          text-transform: uppercase;
          font-weight: 600;
          z-index: 1;
        }
      }

      &.section-2 {
        padding: 0 5rem;
        h1 {
          font-size: 5rem;
          text-transform: uppercase;
        }

        img {
          height: 280px;
          width: 350px;
          margin-bottom: 1.5rem;
        }

        .box {
          border: 2px solid rgba(0, 0, 0, 0.87);
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;

          .box-header {
            position: relative;
            width: 100%;
            text-align: center;
            padding: 1.5rem 0;
            h3 {
              font-size: 2rem;
              font-weight: 300;
              width: 100%;
            }

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #000;
            }
          }

          .box-desc {
            padding: 1rem;
            text-align: center;

            p {
              font-weight: bold;
              font-size: 1.2rem;
              line-height: 1.6;
            }
          }
        }
      }

      &.section-3 {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        flex-direction: row;
        position: relative;
      }
    }
  }

  &.page-4 {
    grid-template-rows: 10% 90% !important;
  }

  // 3rd Page
  &.page-3 {
    .section.section-2 {
      flex-direction: column;
      gap: 0.5rem;
      padding: 0;
      .box-container-snack {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        padding: 0;
      }
      .box-container {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        .box {
          width: 120px;
          border: 3px solid $black;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 15px;
          .first-box {
            text-transform: capitalize;
            font-weight: bold;
            color: #000;
          }

          &.box-1 {
            font-weight: bold;
            height: 50px;
            background: #a6a6a6;
            color: #000;
          }

          &.box-2 {
            max-height: 90px;
            min-height: 60px;
            background: #fff;
            div.recipe {
              height: 100%;
              width: 100%;

              display: flex;
              align-items: center;
              justify-content: center;

              text-align: center;
              position: relative;

              .recipe-name {
                position: absolute;
                left: 50%;
                bottom: 0%;
                height: 30%;
                width: 100%;
                transform: translateX(-50%);
                font-size: 12px;
                padding: 0 0.5rem;
                color: #fff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize;
              }

              .recipe-img {
                width: 100%;
                height: 100%;
                margin-bottom: 0;
                object-fit: cover;

                &.logo {
                  object-fit: contain !important;
                }
              }
            }
          }
        }
      }
    }
  }

  // 4th Page
  &.page-4 {
    .section.section-2 {
      flex-direction: column;
      gap: 0.4rem;
      padding: 0;
      .box-container-snack {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 0;
      }
      .box-container {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        .box {
          width: 100px;
          border: 3px solid $black;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 14px;
          text-align: center;

          &.first-box {
            font-weight: bold;
            color: #000;
            background: white;
          }
          &.box-1 {
            height: 40px;
            font-weight: bold;
            background: #d9d9d9;
            font-size: 12px;
          }
          &.box-2 {
            height: 55px;
          }
          &.box-3 {
            height: 66px;
            background: #d9d9d9;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .header {
              font-weight: bold;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  &.page-recipes {
    padding: 0 20%;
  }
}
</style>
