<template>
  <div class="d-inline-block" v-if="user">
    <v-hover v-slot:default="{ hover }">
      <v-avatar :color="user.gender == 'male' ? 'accent' : 'pink'" :size="size">
        <template v-if="!photoUrl">
          <span class="white--text font-weight-bold" :style="{ fontSize: size/3 + 'px' }">{{ getInitial(user.firstName) }}{{ getInitial(user.lastName) }}</span>
        </template>
        <template v-else>
          <v-img :src="photoUrl" :alt="`${user.firstName} ${user.lastName}`"></v-img>
        </template>

        <v-overlay v-if="hover && editable" absolute>
          <v-btn icon @click="openDialog()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-overlay>
      </v-avatar>
    </v-hover>

    <template v-if="editable">
      <v-dialog
        v-model="status.showDialog"
        max-width="450"
        persistent
      >
        <v-card>
          <v-card-title class="font-weight-bold">Upload Photo</v-card-title>
          <v-card-text>
            <v-form ref="photoForm">
              <v-progress-linear
                v-if="status.uploadProgress"
                :value="status.uploadProgress"
                height="4"
                color="primary"
                class="mb-2"
                reactive
              ></v-progress-linear>

              <v-file-input
                v-model="data.photo"
                placeholder="Select Photo"
                prepend-icon=""
                prepend-inner-icon="mdi-account-box"
                accept="image/*"
                ref="photoInput"
                :rules="[rules.required]"
                outlined
              ></v-file-input>

              <v-btn
                @click="validateForm()"
                :loading="status.uploadingPhoto"
                color="accent white--text"
                class="mr-2"
                depressed
              >Upload</v-btn>

              <v-btn
                @click="closeDialog()"
                :disabled="status.uploadingPhoto"
                depressed
              >Cancel</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import db from '@/firebase/init'
import firebase from 'firebase'
import rules from '@/rules'

export default {
  name: 'UserPhoto',

  props: {
    id: String,
    editable: Boolean,
    size: Number,
    photoSize: String,
  },

  data() {
    return {
      rules,
      photoUrl: null,
      user: null,
    }
  },

  computed: {
    ...mapState({
      status: state => state.user.status,
      data: state => state.user.data,
      currentUser: state => state.user.user,
    })
  },

  watch: {
    'currentUser.photo': function () {
      this.getUrl(this.id)
    },

    'id': function (val) {
      this.getUrl(val)
    },
  },

  methods: {
    ...mapActions('user', [
      'uploadPhoto'
    ]),

    getInitial(name) {
      let nameArray = name.split("")
      return nameArray[0]
    },

    openDialog() {
      this.$store.commit('user/toggleShowDialog', true)
    },

    closeDialog() {
      this.$store.commit('user/toggleShowDialog', false)
      this.$refs.photoForm.reset()
    },

    validateForm() {
      if (this.$refs.photoForm.validate()) {
        this.uploadPhoto()
      }
    },

    getUrl(id) {
      db.collection('users')
      .doc(id)
      .get()
      .then(doc => {
        if (doc.exists) {
          let data = doc
          this.user = data.data()
          this.user.id = data.id
          this.user.ref = data.ref

          if (this.user.photo) {
            var storage  = firebase.storage()

            storage.ref(`profilephotos/${this.photoSize}_${this.user.photo}`).getDownloadURL()
            .then(url => {
              this.photoUrl = url
            })
            .catch(() => {
              storage.ref(`profilephotos/${this.user.photo}`).getDownloadURL()
              .then(url => {
                this.photoUrl = url
              })
            })
          }
          else {
            this.photoUrl = null
          }
        }
      })
      .catch(error => {
        this.$store.dispatch('showError', error.message)
      })

    },
  },

  created() {
    this.getUrl(this.id)
  }

}

</script>
