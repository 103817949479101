import 'jspdf-autotable'
import jsPDF from 'jspdf'
// import { pullAt } from 'lodash'

export default {
  methods: {
    downloadMealPlanPdf() {
      return new Promise((resolve) => {
        const doc = new jsPDF('landscape')
  
        let logoImg = new Image
        // let mealzeeImg = new Image

        // mealzeeImg.src = 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fpowered_by.png?alt=media&token=53f85656-97eb-49ba-9fe1-3389861b9b6b'

        logoImg.src = this.user.customLogo ? this.user.customLogoUrl : 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fmealzee_pdf.png?alt=media&token=b078269d-186b-4249-b3c1-b15b50ad041e'
        var rgb = null
  
        if (this.user.brandColor) {
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.user.brandColor)
          rgb = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
        }
  
        var primaryColor = rgb || [151, 200, 31]
  
        logoImg.onload = () => {
          if(this.user?.isStudent) {
            doc.autoTable({
              head: [
                [{ content: '*Student Sample Only', styles: { fontStyle: 'bold', fontSize: 12, textColor: [255, 0, 0]}}]
              ],
              theme: 'plain'
            })
          }
          if(this.$store.getters['subscriptions/isOnLow']) {
            doc.autoTable({
              head: [
                [{ content: ''}, {content: 'Powered by Mealzee'}]
              ],
              theme: 'plain',
              body: [[''], ['']],
              columnStyles: {
                0: {cellWidth: 140},
                1: {cellWidth: 40},
              },
              didDrawCell: function(data) {
                if (data.row.index == 0 && data.column.index == 0 && data.cell.section == 'head') {
                  doc.addImage(logoImg, 'png', (data.cell.x + 1),  data.cell.y, logoImg.width / (logoImg.height/23), 23)
                }
            
                // if (data.row.index == 0 && data.column.index == 1 && data.cell.section == 'head') {
                //   doc.addImage(mealzeeImg, 'png', (data.cell.x + 1),  data.cell.y, logoImg.width / (logoImg.height/25), 23)
                // }
              },
            })
          } else {
            doc.autoTable({
              head: [
                [ 
                  { content: this.plan.name, colSpan: 3, styles: { fontStyle: 'bold', fontSize: 22, halign: 'center' } }
                ]
              ],
              didDrawCell: function(data) {
                if (data.column.index == 0 && data.cell.section == 'head') {
                  doc.addImage(logoImg, 'png', (data.cell.x + 1),  data.cell.y, logoImg.width / (logoImg.height/23), 23)
                }
              },
              theme: 'plain',
              tableWidth: 'auto',
            })
          }

          var body = [
            [
                { content: '', styles: { fontSize: 15 }}
            ]
          ]
  
          if (!['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(this.user.role)) {
            body = body.concat(
              [
                {content: `Name: ${this.user.firstName} ${this.user.lastName}`, styles: { fontSize: 9 }}, 
                {content: `Weight: ${this.goal ? this.goal.weight : ''}kg`, styles: { fontSize: 8 }}, 
                {content: `Height: ${this.goal ? this.goal.height : ''}cm`, styles: { fontSize: 8 }}
              ]
            )
          }
  
          doc.autoTable({
            body,
            theme: 'plain',
            tableWidth: 'auto',
          })
          
          let headers = [
            'Day', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
          ]
        
          let data = [
            [{ content: 'Main Meals', colSpan: 8, styles: { fontStyle: 'bold' } }]
          ]
        
          this.mainMeals.forEach(meal => {
            data.push([
              
              this.$options.filters.capitalize(meal), 
              { content: this.getRecipeName(meal, 1), styles: { textColor: this.getRecipeName(meal, 1) == 'none' ? '#000000' : '#08c7fb' } },
              { content: this.getRecipeName(meal, 2), styles: { textColor: this.getRecipeName(meal, 2) == 'none' ? '#000000' : '#08c7fb' } },
              { content: this.getRecipeName(meal, 3), styles: { textColor: this.getRecipeName(meal, 3) == 'none' ? '#000000' : '#08c7fb' } },
              { content: this.getRecipeName(meal, 4), styles: { textColor: this.getRecipeName(meal, 4) == 'none' ? '#000000' : '#08c7fb' } },
              { content: this.getRecipeName(meal, 5), styles: { textColor: this.getRecipeName(meal, 5) == 'none' ? '#000000' : '#08c7fb' } },
              { content: this.getRecipeName(meal, 6), styles: { textColor: this.getRecipeName(meal, 6) == 'none' ? '#000000' : '#08c7fb' } },
              { content: this.getRecipeName(meal, 7), styles: { textColor: this.getRecipeName(meal, 7) == 'none' ? '#000000' : '#08c7fb' } },
            ])
          })
          
          var snacks = this.goal.snacks || null
  
          if (snacks) {
            data.push(
              [{ content: 'Snacks', colSpan: 8, styles: { fontStyle: 'bold' } }]
            )
        
            for(let i=1; i <= snacks; i++) {
              data.push([
                `Snack ${i}`, 
                { content: this.getRecipeName(`snack${i}`, 1), styles: { textColor: this.getRecipeName(`snack${i}`, 1) == 'none' ? '#000000' : '#08c7fb' } }, 
                { content: this.getRecipeName(`snack${i}`, 2), styles: { textColor: this.getRecipeName(`snack${i}`, 2) == 'none' ? '#000000' : '#08c7fb' } }, 
                { content: this.getRecipeName(`snack${i}`, 3), styles: { textColor: this.getRecipeName(`snack${i}`, 3) == 'none' ? '#000000' : '#08c7fb' } }, 
                { content: this.getRecipeName(`snack${i}`, 4), styles: { textColor: this.getRecipeName(`snack${i}`, 4) == 'none' ? '#000000' : '#08c7fb' } }, 
                { content: this.getRecipeName(`snack${i}`, 5), styles: { textColor: this.getRecipeName(`snack${i}`, 5) == 'none' ? '#000000' : '#08c7fb' } }, 
                { content: this.getRecipeName(`snack${i}`, 6), styles: { textColor: this.getRecipeName(`snack${i}`, 6) == 'none' ? '#000000' : '#08c7fb' } }, 
                { content: this.getRecipeName(`snack${i}`, 7), styles: { textColor: this.getRecipeName(`snack${i}`, 7) == 'none' ? '#000000' : '#08c7fb' } }, 
              ])
            }
          }
        
          if (!this.plan.hideEnergy) {
            let total = [
              { content: 'Total Energy', styles: { fontStyle: 'bold' } }
            ]
          
            for (let i = 1; i <= 7; i++) {
              if (this.user.energyUnit == 'cal')
                total.push({ content: `${ this.formatAmount(this.$options.filters.kjToCal(this.getTotalNutrientValue(i, 'energy')))} Cal`, styles: { fontStyle: 'bold' } })
              else
                total.push({ content: `${ this.formatAmount(this.getTotalNutrientValue(i, 'energy'))} kJ`, styles: { fontStyle: 'bold' } })
            }
          
            data.push(total)
          }
        
          doc.autoTable({
            theme: 'grid',
            headStyles: {fillColor: primaryColor},
            styles: { fontSize: 8 },
            head: [headers],
            body: data,
            columnStyles: {
              0: {cellWidth: 25},
              1: {cellWidth: 35},
              2: {cellWidth: 35},
              3: {cellWidth: 35},
              4: {cellWidth: 35},
              5: {cellWidth: 35},
              6: {cellWidth: 35},
              7: {cellWidth: 35},
            },
            didDrawCell: (data) => {
              var lastRow = typeof data.row.raw[0] == 'object' && data.row.raw[0].content == 'Total Energy'
              var none = data.cell.raw.content  == 'none'
              if (data.cell.section == 'body' && data.column.index && !lastRow && !none) {
                doc.link(data.cell.x, data.cell.y, data.cell.width, data.cell.height, { url: `${window.origin}${this.$router.resolve({ name: 'DownloadRecipe', query: { id: this.getRecipeID(data.cell.raw.content), plan: this.plan.id,meal: this.getMealID(data.row.raw[0], data.column.index),b: this.user.id, 'hide-energy': this.plan.hideEnergy ? 'true' : 'false' } }).href}` })
              }
            },
          })
        
          // Hide the Entire Nutrition Panel
          if(!this.plan.hideEnergy) {
            let headersTwo = [
              'Day', 'Weight (g)', `Energy ${ this.user.energyUnit == 'cal' ? '(Cal)' : '(kJ)' }`, 'Protein (g)', 'Fat (g)', 'Carbohydrate (g)', 'Sugar (g)', 'Fibre (g)', 'Sodium (mg)'
            ]

            // if (this.plan.hideEnergy)
            //   pullAt(headersTwo, [2])
          
            let dataTwo = []
            let weekDays = [
              'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Average per Day'
            ]
          
            weekDays.forEach((day, i) => {
              var data = [
                { content: day, styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'weight')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.user.energyUnit == 'cal' ? this.$options.filters.kjToCal(this.getTotalNutrientValue((i + 1), 'energy')) : this.getTotalNutrientValue((i + 1), 'energy')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'protein')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'fat')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'carbohydrate')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'sugar')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'fibre')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
                { content: this.formatAmount(this.getTotalNutrientValue((i + 1), 'sodium')), styles: { fontStyle: i == 7 ? 'bold' : 'normal' } }, 
              ]

              // if (this.plan.hideEnergy)
              //   pullAt(data, [2])
              
              dataTwo.push(data)
            })
    
            dataTwo.push([
              { content: 'Macros', styles: { fontStyle: 'bold' }, colSpan: 3 }, 
              { content: this.$options.filters.percent((this.getTotalNutrientValue((8), 'protein') * 16.7) / this.getTotalNutrientValue((8), 'energy')), styles: { fontStyle: 'bold' } }, 
              { content: this.$options.filters.percent((this.getTotalNutrientValue((8), 'fat') * 37.7) / this.getTotalNutrientValue((8), 'energy')), styles: { fontStyle: 'bold' } }, 
              { content: this.$options.filters.percent((this.getTotalNutrientValue((8), 'carbohydrate') * 16.7) / this.getTotalNutrientValue((8), 'energy')), styles: { fontStyle: 'bold' } }, 
              { content: '', colSpan: 3 }, 
            ])
          
            doc.autoTable({
              theme: 'grid',
              headStyles: {fillColor: [70, 70, 70], fontSize: 6},
              styles: { fontSize: 8 },
              head: [headersTwo],
              body: dataTwo,
              columnStyles: {
                0: {cellWidth: 30},
                1: {cellWidth: 30},
                2: {cellWidth: 30},
                3: {cellWidth: 30},
                4: {cellWidth: 30},
                5: {cellWidth: 30},
                6: {cellWidth: 30},
                7: {cellWidth: 30},
                8: {cellWidth: 30},
              },
            })
          }

          doc.save(`${this.plan.name}.pdf`)
          resolve(this.plan.name)
        }
      })
    },

    /*------------------------------------------------------------------------------
     * FORMAT NUMBER
     *----------------------------------------------------------------------------*/
    formatAmount(value) {
      return this.$options.filters.numberFormat(
        this.$options.filters.amount(value)
      )
    }
  }
}