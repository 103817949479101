<template>
  <div class="mb-5">
    <v-btn @click="dialog = true" class="text-none" small outlined>
      <span>Report</span>
      
      <v-tooltip max-width="200" right>
        <template #activator="{ on }">
          <v-icon v-on="on" small right>mdi-information-outline</v-icon>
        </template>
        <span>Have you found an issue with this item? Please flag it for us so we can address the issue and have it fixed</span>
      </v-tooltip>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card outlined>
        <v-card-text class="pa-5">
          <v-form ref="form">
            <v-textarea
              v-model="data.issue"
              :rules="[rules.required]"
              label="Recipe issue"
              outlined
            ></v-textarea>

            <v-btn
              @click="validateForm()"
              :loading="status.adding"
              color="primary" 
              class="mr-2" 
              depressed
            >Submit</v-btn>
            
            <v-btn 
              @click="closeDialog()" 
              :disabled="status.adding"
              text
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  props: {
    recipe: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.report.data,
      status: state => state.report.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('report', [
      'addReport'
    ]),

    closeDialog() {
      this.dialog = false
      this.$store.commit('report/resetData')
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.addReport({recipe: this.recipe.id, createdBy: this.recipe.user, recipeName: this.recipe.name}))
        .then(() => {
          this.closeDialog()
        })
      }
    }
  }
}
</script>