<template>
  <div v-if="user">
    <v-card
      v-if="recipe && meal"
      class="fill-height"
      flat
      tile
      color="transparent"
    >
      <div class="d-flex justify-space-between px-5">
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="unsave({ user, recipe })"
                v-if="savedRecipes.includes(recipe.id)"
                class="mt-n2 mr-n3"
                color="red"
                v-on="on"
                absolute
                x-small
                left
                icon
                top
              >
                <v-icon v-if="user.role == 'member'" color="error" small
                  >mdi-heart</v-icon
                >
                <v-icon v-else color="warning" small>mdi-bookmark</v-icon>
              </v-btn>
              <v-btn
                @click="save({ user, recipe })"
                v-else
                class="mt-n2 mr-n3"
                v-on="on"
                absolute
                x-small
                left
                icon
                top
              >
                <v-icon v-if="user.role == 'member'" small
                  >mdi-heart-outline</v-icon
                >
                <v-icon v-else small>mdi-bookmark-outline</v-icon>
              </v-btn>
            </template>
            <span>Save Meal</span>
          </v-tooltip>
        </div>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-chip class="ma-2" color="green" text-color="white" x-small>
                  {{ $store.getters['mealplans/getRecipeCount'](recipe.id) }}
                </v-chip>
              </span>
            </template>
            <span
              >Number of times this recipe is used throughout this folder</span
            >
          </v-tooltip>

          <v-tooltip v-if="!meal.locked" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="
                  plan?.requestStatus == 'approved'
                    ? (reqWarning = true)
                    : removeMeal()
                "
                class="mt-n2 mr-n3"
                color="red"
                v-on="on"
                absolute
                x-small
                right
                icon
                top
              >
                <v-icon x-small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Remove Meal</span>
          </v-tooltip>
        </div>
      </div>
      <v-card-text class="fill-height d-flex flex-column">
        <v-card max-width="100" class="mx-auto mb-2" outlined tile>
          <recipe-image :recipe="recipe" size="medium" />
        </v-card>

        <div :style="{ lineHeight: '1.3' }">{{ recipe.name }}</div>
        <div class="mb-2 d-flex" style="gap: 0.5rem">
          <div
            v-if="recipe.servingValues && user.energyUnit == 'cal'"
            class="caption"
          >
            {{ recipe.servingValues.energy | kjToCal | amount | numberFormat }}
            Cal
          </div>
          <div v-else-if="recipe.servingValues" class="caption">
            {{ recipe.servingValues.energy | amount | numberFormat }} kJ
          </div>

          <div class="caption">
            F {{ recipe.servingValues.fat | amount | numberFormat }}g
          </div>
          <div class="caption">
            P {{ recipe.servingValues.protein | amount | numberFormat }}g
          </div>
          <div class="caption">
            C {{ recipe.servingValues.carbohydrate | amount | numberFormat }}g
          </div>
        </div>

        <div class="d-flex justify-space-between align-center mt-auto gap-5">
          <v-btn
            @click="
              $store.getters['subscriptions/subscribed']
                ? $emit('showCard', {
                    recipe,
                    serves: meal.serves,
                    mealPrep: meal.mealPrep,
                    day,
                    mealTime: meal.meal,
                    meal,
                  })
                : $root.$emit('showSubscribeDialog')
            "
            color="primary"
            outlined
            x-small
            >View Card</v-btn
          >

          <v-chip class="px-0" small>
            <v-btn
              @click="
                $store.dispatch('mealplans/updateMealField', {
                  meal,
                  silent: true,
                  field: 'serves',
                  value: parseInt(meal.serves) - 1,
                })
              "
              :disabled="parseInt(meal.serves) < 2"
              v-if="!meal.mealPrep"
              icon
              x-small
            >
              <v-icon x-small>mdi-minus</v-icon>
            </v-btn>

            <span class="px-1">{{ meal.serves }}</span>

            <v-btn
              @click="
                $store.dispatch('mealplans/updateMealField', {
                  meal,
                  silent: true,
                  field: 'serves',
                  value: parseInt(meal.serves) + 1,
                })
              "
              v-if="!meal.mealPrep"
              icon
              x-small
            >
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </v-chip>

          <v-tooltip v-if="!meal.locked" bottom>
            <template #activator="{ on }">
              <v-btn
                @click="
                  $store.dispatch('mealplans/updateMealField', {
                    meal,
                    value: true,
                    value: true,
                    field: 'locked',
                    message: 'Meal locked',
                  })
                "
                v-on="on"
                x-small
                icon
              >
                <v-icon small>mdi-lock-off</v-icon>
              </v-btn>
            </template>
            <span class="caption">Lock meal</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template #activator="{ on }">
              <v-btn
                @click="
                  $store.dispatch('mealplans/updateMealField', {
                    meal,
                    field: 'locked',
                    value: false,
                    message: 'Meal unlocked',
                  })
                "
                v-on="on"
                x-small
                icon
              >
                <v-icon small>mdi-lock</v-icon>
              </v-btn>
            </template>
            <span class="caption">Unlock meal</span>
          </v-tooltip>
        </div>

        <v-tooltip max-width="150" bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-checkbox
                @change="mpUpdated($event, meal)"
                v-model="meal.mealPrep"
                v-if="meal.origin"
                hide-details
                label="MP"
                dense
              ></v-checkbox>
            </div>
          </template>
          <span
            >Tick this box if this meal was prepared earlier for the purpose of
            meal prepping.</span
          >
        </v-tooltip>
      </v-card-text>
    </v-card>
    <v-dialog max-width="380" v-model="reqWarning">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">CAUTION</span>
        </v-card-title>
        <v-card-text>
          <div>
            ANY EDITS TO THIS MEAL PLAN WILL REMOVE THE DIETITIAN APPROVED
            STAMPING AND WOULD REQUIRE A REVIEW TO HAVE IT APPROVED ONCE AGAIN
          </div>
          <div class="mt-5">
            <v-btn
              @click="removeMeal()"
              color="warning white--text"
              small
              class="mr-2"
              depressed
              >Continue</v-btn
            >
            <v-btn
              @click="reqWarning = false"
              color="black--text"
              class="mr-2"
              small
              depressed
              >close</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showEbookUpdate"
        max-width="290"
    >
        <v-card>
        <v-card-title class="headline">
            Notice
        </v-card-title>
    
        <v-card-text>
            <p>
              Please update the ebook links if in case the update is not reflecting. Thank you.
            </p>
        </v-card-text>
    
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            text
            @click="showEbookUpdate = false"
            > 
            Okay
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import RecipeImage from '@/components/RecipeImage';

export default {
  props: {
    meal: {
      type: Object,
      required: true,
    },
    mealType: String,
    day: Number,
    plan: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      testAdd: 1,
      reqWarning: false,
      showEbookUpdate: false,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      rcps: (state) => state.recipes.recipes,
      myrcps: (state) => state.recipes.myRecipes,
      meals: (state) => state.mealplans.meals,
      status: (state) => state.mealplans.status,
    }),

    recipe: function () {
      let recipe = this.rcps.find((r) => r.id == this.meal.recipe);

      if (!recipe) recipe = this.myrcps.find((r) => r.id == this.meal.recipe);

      return recipe || {};
    },
    savedRecipes() {
      return this.user.savedRecipes || [];
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    meal: {
      handler(meal) {
        if (meal.recipe) {
          this.$store.dispatch('recipes/getRecipe', {
            id: meal.recipe,
            ingredients: true,
          });
        }
      },
      immediate: true,
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecipeImage,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', ['saveRecipe', 'unsaveRecipe']),
    removeMeal() {
      this.reqWarning = false;
      this.$emit('remove', {
        meal: this.meal,
        recipe: this.recipe,
      });

      this.$store.commit('mealplans/removeFromAllMeals', this.recipe.id);

      if (this.plan?.requestStatus == 'approved') {
        this.$store.dispatch('mealplans/updateMealField', {
          value: 'edited',
          field: 'requestStatus',
          meal: this.plan,
          silent: true,
        });
      }
    },

    mpUpdated(e, meal) {
      let origin = this.meals.find((m) => m.id == meal.origin.id);

      if (e) {
        Promise.all([
          this.$store.dispatch('mealplans/updateMealField', {
            meal,
            field: 'mealPrep',
            value: e,
            silent: true,
          }),
          this.$store.dispatch('mealplans/updateMealField', {
            meal,
            field: 'serves',
            value: 0,
            silent: true,
          }),
        ]).then(() => {
          if (origin) {
            this.$store.dispatch('mealplans/updateMealField', {
              silent: true,
              meal: origin,
              field: 'serves',
              value: origin.serves + 1,
            });

            this.showEbookUpdate = true
          }
        });
      } else if (!e) {
        Promise.all([
          this.$store.dispatch('mealplans/updateMealField', {
            meal,
            field: 'mealPrep',
            value: e,
            silent: true,
          }),
          this.$store.dispatch('mealplans/updateMealField', {
            meal,
            field: 'serves',
            value: 1,
            silent: true,
          }),
        ]).then(() => {
          if (origin) {
            this.$store.dispatch('mealplans/updateMealField', {
              silent: true,
              meal: origin,
              field: 'serves',
              value: origin.serves - 1,
            });

            this.showEbookUpdate = true
          }
        });
      }
    },
    save(data) {
      Promise.resolve(this.saveRecipe(data))
        .then(() => {
          this.loading = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit('recipes/insertSavedRecipe2', this.recipe);
          this.loading = false;
        });
    },
    unsave(data) {
      Promise.resolve(this.unsaveRecipe(data))
        .then(() => {
          this.loading = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit('recipes/removeSavedRecipe', this.recipe.id);
          this.loading = false;
        });
    },
  },
};
</script>
