<template>
  <div>
    <v-dialog
      class="overflow-visible fill-height"
      no-click-animation
      max-width="1300"
      v-model="dialog"
    >
      <close-button @click="closeDialog()" overflow />

      <v-card :loading="status.inserting" >
        <v-card-title :class="$classes.cardTitle">
          <h5 class="font-weight-bold title">Find a meal</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-5 pt-5 pb-0 pr-0 fill-height">
          <v-row>
            <v-col class="fill-height" xs="6" sm="6" md="4" lg="4" xl="4" style="paddingRight: 5rem">
              <v-card 
                :max-height="$window.innerHeight - 190"
                class="overflow-y-scroll overflow-x-hidden"
                flat
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-text-box-search-outline"
                  placeholder="Search meal"
                  class="mb-5"
                  hide-details
                  clearable
                  outlined
                  dense
                ></v-text-field>
                <v-select
                  label="Filter by Company"
                  v-if="view == 'ready'"
                  v-model="company"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                ></v-select>

                <v-card outlined class="mb-3">
                  <v-expansion-panels>
                    <!-- FILTER BY MEAL TAGS -->
                    <v-expansion-panel v-if="mealTags.length">
                      <v-expansion-panel-header class="font-weight-bold">
                        Filter by Meal Tags
                        <v-badge
                          v-if="tagsFilter?.mealTags && tagsFilter.mealTags.length"
                          color="red"
                          :content="tagsFilter.mealTags.length"
                          :style="{ 'flex': 'none'}"
                          offset-x="-8"
                          offset-y="10"
                        >
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox 
                          v-model="tagsFilter.mealTags"
                          v-for="tag in mealTags"
                          class="mt-1 caption"
                          :label="tag.name" 
                          :value="tag.id"
                          :key="tag.id"
                          hide-details
                          multiple
                          dense
                        ></v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    
                    <!-- FILTER BY NUTRTITION TAGS -->
                    <v-expansion-panel v-if="nutritionTags.length">
                      <v-expansion-panel-header class="font-weight-bold">
                        Filter by Nutrition Tags
                        <v-badge
                          v-if="tagsFilter?.nutritionTags && tagsFilter.nutritionTags.length"
                          color="red"
                          :content="tagsFilter.nutritionTags.length"
                          :style="{ 'flex': 'none'}"
                          offset-x="-8"
                          offset-y="10"
                        >
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox 
                          v-model="tagsFilter.nutritionTags"
                          v-for="tag in nutritionTags"
                          class="mt-1 caption"
                          :label="tag.name" 
                          :value="tag.id"
                          :key="tag.id"
                          hide-details
                          multiple
                          dense
                        ></v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
                <v-sheet
                  color="transparent"
                  class="mt-5"
                  v-if="view == 'all'"
                >
                  <v-btn-toggle color="primary" mandatory 
                    class="my-5"
                  >
                    <v-btn @click="roleView = 'dietitian'" x-small>Dietitians<span v-if="selectedDietitian" class="custom-badge">1</span></v-btn>
                    <v-btn @click="roleView = 'nutritionist'" x-small>Nutritionists<span v-if="selectedNutritionist" class="custom-badge">1</span></v-btn>
                    <v-btn @click="roleView = 'naturopath'" x-small>Naturopaths<span v-if="selectedNaturopath" class="custom-badge">1</span></v-btn>
                  </v-btn-toggle>
                  <v-combobox
                      v-show="roleView == 'dietitian'"
                      dense
                      label="Filter by Dietitians"
                      outlined
                      clearable
                      :loading="status.gettingRecipesByDietitian"
                      v-model="selectedDietitian"
                      :items="confDietitians"
                  >
                  </v-combobox>
                  <v-combobox
                      v-show="roleView == 'nutritionist'"
                      dense
                      label="Filter by Nutritionists"
                      outlined
                      clearable
                      v-model="selectedNutritionist"
                      :items="confNutritionists"
                  >
                  </v-combobox>
                  <v-combobox
                      v-show="roleView == 'naturopath'"
                      dense
                      label="Filter by Naturopaths"
                      outlined
                      clearable
                      v-model="selectedNaturopath"
                      :items="confNaturopaths"
                  >
                  </v-combobox>
                </v-sheet>
                <v-checkbox
                  label="Remove Cal/kJ Filter"
                  v-model="allRecipeCheckbox"
                  dense
                >
                </v-checkbox>
                <v-checkbox
                  label="Sort by Energy"
                  v-model="orderByEnergy"
                  dense
                >
                </v-checkbox>
                <v-radio-group
                    dense
                    class="mt-0 pt-0"
                    row
                    v-model="sort"
                >
                  <v-radio
                    label="Ascending"
                    value="asc"
                  >
                  </v-radio>
                  <v-radio
                    label="Descending"
                    value="desc"
                  >
                  </v-radio>
                </v-radio-group>
              </v-card>
            </v-col>

            <v-col class="fill-height" xs="6" sm="6" md="8" lg="8" xl="8" style="marginLeft: -4rem;">
              <v-card 
                class="overflow-y-scroll overflow-x-hidden pr-5"
                :max-height="$window.innerHeight - 190"
                flat
              >
                <v-row class="my-3 mx-2 justify-center">
                  <v-btn 
                    v-for="(stat, i) in [
                      { stat: 'all', text: 'All Recipes' },
                      { stat: 'my', text: 'My Recipes' },
                      { stat: 'saved', text: 'Saved' },
                      { stat: 'ready', text: 'Ready Made' },
                    ]"
                    :color="view == stat.stat ? 'primary' : 'grey'"
                    class="mr-2 text-none text-capitalize pa-sm-2"
                    :outlined="view !== stat.stat"
                    @click="setView(stat.stat)"
                    depressed
                    :key="i"
                    small
                  >
                    <span v-if="stat.stat == 'saved' && user.role == 'member' ">Favourites</span>
                    <span v-else>{{ stat.text }}</span>
                  </v-btn>
                </v-row>
                <preloader v-if="gettingRecipes" />
                  <template v-if="!gettingRecipes && confRecipes.length">
                    <v-row dense>
                      <v-col v-for="recipe in confRecipes" :key="recipe.id" xs="12" sm="12" md="4" xl="4" lg="4">
                        <RecipeCard
                          @showCard="$emit('showCard', $event)"
                          @selected="insertMeal"
                          :recipe="recipe"
                          :cell="cell"
                        />
                      </v-col>
                    </v-row>

                    <!-- <v-btn 
                      @click="getRecipes(cell.meal)" 
                      :loading="recipesStatus.loadingMore"
                      color="primary" 
                      class="mt-5" 
                      small
                    >Load More</v-btn> -->
                  </template>
                  
                  <template v-if="!gettingRecipes && !confRecipes.length">
                    <v-alert type="info" border="left" class="mb-0" dense> No recipes found </v-alert>
                  </template>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="blue-grey lighten-5">
            <div>
                <v-btn
                    @click="loadMoreRecipes()"
                    :loading="status.getting"
                    v-if="currentPageCount >= pageNumber"
                    class="mr-2"
                    color="secondary"
                    depressed
                    small
                >Load More</v-btn>
                
                <span class="caption">showing {{ confRecipes.length }} {{ pluralize('recipes', confRecipes.length) }}</span>
            </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="400"
      persistent
      :value="false"
      v-model="showConfirmDialog"
    >
      <v-card>
        <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
            <v-card-text >
                This may result in a lower or higher than expected average energy (kJ/Cal)
                <div class="mt-5 d-flex" style="gap: 1rem">
                    <v-btn :loading="loadingAllRecipes" small color="info" @click="showAllRecipes()">
                      Confirm
                    </v-btn>
                    <v-btn small color="secondary" @click="closeAllRecipeDialog()">
                      Cancel
                    </v-btn>
                </div>
            </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase'
import { mapState, mapActions } from "vuex"
import { uniqBy, orderBy, intersection, capitalize } from "lodash"
import pluralize from 'pluralize'

import RecipeCard from "../../recipe/components/RecipeCardSmall"

let getFunc = firebase.functions().httpsCallable('getRecipes')

export default {
  props: {
    cell: Object,
    plan: Object,
    show: Boolean,
    tagsFilter: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      dialog: false,
      getting: false,
      showConfirmDialog: false,
      allRecipeCheckbox:false,
      showAllRecipesBol: false,
      loadingAllRecipes: false,
      selectedNaturopath: '',
      selectedNutritionist: '',
      pageNumber: 1,
      pageSize: 20,
      sort: 'asc',
      orderByEnergy: false,
      pluralize,
      selectedDietitian: '',
      roleView: 'dietitian',
      view: 'all',
      company: '',
      lastOpenMeal: null,
      gettingRecipes: true,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecipeCard,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      companies: (state) => state.recipe.companies,
      recipes: (state) => state.recipes.recipesByMeal,
      myRecipes: (state) => state.recipes.myRecipes,
      status: (state) => state.mealplans.status,
      recipesStatus: (state) => state.recipes.status,
      dietitians: (state) => state.members.members,
      savedRecipes: (state) => state.recipes.savedRecipes,
      naturopaths: (state) => state.members.naturopaths,
      nutritionists: (state) => state.members.nutritionists,
    }),

    confRecipes: function () {
      let recipes 

      if(this.view === 'all')
        recipes = this.recipes
      else if(this.view === 'saved')
        recipes = this.savedRecipes
      else if(this.view == 'ready')
        recipes = this.recipes
      else
        recipes = this.myRecipes

      recipes = uniqBy(recipes, 'id')

      // FILTER BY DIETITIAN
      if(this.selectedDietitian && this.view === 'all') {
        recipes = recipes.filter((recipe) => {
         return recipe.user === this.getDietitianId(this.selectedDietitian)
        })
      }

      // FILTER BY NUTRITIONIST
      if(this.selectedNutritionist && this.view === 'all') {
        recipes = recipes.filter((recipe) => {
         return recipe.user === this.getNutritionistId(this.selectedNutritionist)
        })
      }

      // FILTER BY NATUROPATHS
      if(this.selectedNaturopath && this.view === 'all') {
        recipes = recipes.filter((recipe) => {
         return recipe.user === this.getNaturopathId(this.selectedNaturopath)
        })
      }

      // FILTER FOR MEAL TIME
      // if (this.cell && recipes.length && this.view === 'all') {
      //   recipes = recipes.filter((recipe) => {
      //     if (recipe.mealTime) {
      //       let mealtime = recipe.mealTime
            
      //       mealtime = map(mealtime, (val) => {
      //         return val.toLowerCase()
      //       })

      //       let meal = this.cell.meal.toLowerCase().includes("snack") ? "snack" : this.cell.meal.toLowerCase()
      //       if (meal == 'desert') meal = 'dessert'
      //       return mealtime.includes(meal)
      //     } 
      //     else {
      //       return false
      //     }
      //   })

      // }

      if(this.view === 'ready') {
        recipes = recipes.filter((recipe) => {
          return recipe?.toggleCompany
        })
      }

      if(this.view === 'ready' && this.company) {
        recipes = recipes.filter((recipe) => {
          return recipe.company == this.company
        })
      }

      if(this.view === 'all') {
        recipes = recipes.filter((recipe) => {
          return !recipe?.toggleCompany
        })
      }

      // NUTRTION TAGS FILTER
      if (this.tagsFilter && this.tagsFilter.nutritionTags && this.tagsFilter.nutritionTags.length) {
        recipes = recipes.filter(recipe => {
          let count = intersection(recipe.nutritionTags, this.tagsFilter.nutritionTags).length
          return count == this.tagsFilter.nutritionTags.length
        })
      }

      // MEAL TAGS FILTER
      if (this.tagsFilter && this.tagsFilter.mealTags && this.tagsFilter.mealTags.length) {
        recipes = recipes.filter(recipe => {
          let count = intersection(recipe.tags, this.tagsFilter.mealTags).length
          return count == this.tagsFilter.mealTags.length
        })
      }

      // INGREDIENTS
      if (this.tagsFilter && this.tagsFilter.ingredientTags && this.tagsFilter.ingredientTags.length) {
        recipes = recipes.filter(recipe => {
          let excluded = false

          this.tagsFilter.ingredientTags.forEach(tag => {
            if (recipe.name.toLowerCase().includes(tag.toLowerCase()))
              excluded = true
          })

          let ingredientIds = this.$store.state.recipes.recipeIngredientIds.find(r => r.recipe == recipe.id)

          if (ingredientIds) {
            ingredientIds.ids.forEach(id => {
              let ingredient = this.$store.state.ingredients.ingredients.find(i => i.id == id)
              
              if (ingredient) {
                this.tagsFilter.ingredientTags.forEach(tag => {
                  console.log(ingredient.name)
                  if (ingredient.name.toLowerCase().includes(tag.toLowerCase()))
                    excluded = true
                })
              }
              
            })
          } else {
            this.tagsFilter.ingredientTags.forEach(tag => {
              recipe.ingredients.forEach(ing => {
                if(ing.food.toLowerCase().includes(tag.toLowerCase()))
                  excluded = true
              })
            })
          }

          return !excluded
        })
      }
      
      // FILTER RECIPE BY KJ RANGE
      if(!this.showAllRecipesBol && (this.view === 'all' || this.view === 'ready')) {
        if (this.cell && !this.cell.meal.includes('snack') && this.cell.meal !== 'dessert') {
          let mainEnergy = this.$store.getters['goal/mainMealsEnergy']
          mainEnergy = parseInt(mainEnergy)
          recipes = recipes.filter(recipe => {
            return recipe.servingValues.energy >= (mainEnergy - (mainEnergy * 0.15)) && recipe.servingValues.energy <= (mainEnergy + (mainEnergy * 0.15))
          })
        }
        else if (this.cell) {
          let snackEnergy = this.$store.getters['goal/snacksEnergy']
          snackEnergy = parseInt(snackEnergy)
          recipes = recipes.filter(recipe => {
            return recipe.servingValues.energy >= (snackEnergy - (snackEnergy * 0.25)) && recipe.servingValues.energy <= (snackEnergy + (snackEnergy * 0.25))
          })
        }
      }

      // SEARCH FILTER
      if (this.search) {
        recipes = recipes.filter((recipe) => {
          return recipe.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      // Attach energy value for sorting
      recipes = recipes.map(recipe => ({ ...recipe, energy: recipe.servingValues.energy }))

      return orderBy(recipes.slice(0, this.pageNumber * this.pageSize), this.orderByEnergy ? 'energy' : 'name', this.sort)
    },

    confDietitians: function() {
      if(!this.dietitians.length) return []

      return this.dietitians.map((dietitian) => `${dietitian.firstName} ${dietitian.lastName}`).sort()
    },

    confNutritionists: function() {
      if(!this.nutritionists.length) return []

      return this.nutritionists.map((nutritionist) => `${nutritionist.firstName} ${nutritionist.lastName}`).sort()
    },

    confNaturopaths: function() {
      if(!this.naturopaths.length) return []

      return this.naturopaths.map((naturopath) => `${naturopath.firstName} ${naturopath.lastName}`).sort()
    },

    nutritionTags: function () {
      return this.$store.getters['nutritiontags/tags']
    },
    
    mealTags: function () {
      return this.$store.getters['tags/tags']
    },
    currentPageCount: function () {
      return Math.floor(this.confRecipes.length / 20) 
    }
  },
  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'allRecipeCheckbox': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal)
          this.showConfirmDialog = true
        else {
          this.showConfirmDialog = false
          this.showAllRecipesBol = false
        }
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('nutritiontags', [
      'getTags'
    ]),
    
    ...mapActions('recipes', [
      'getMyRecipes',
      'getSavedRecipes',
      'getRecipesByMeal',
    ]),

    ...mapActions('members', [
      'getAllDietitians',
      'getAllNaturopaths',
      'getAllNutritionists',
    ]),

    ...mapActions('recipe', [
      'getCompanies'
    ]),

    close() {
      this.dialog = false
      this.$store.commit('mealplans/setData', {})
    },

    insertMeal(recipe) {
      let data = {
        cell: this.cell,
        recipe,
      }

      // this.$store.commit('mealplans/setAllMeals', recipe.id)
      Promise.resolve(
        this.$store.dispatch('mealplans/insertMeal', data)
      )
      .then(() => {
        if (this.plan.requestStatus == 'approved') {
          this.$store.dispatch('mealplans/updateMealField', {
            value: 'edited',
            field: 'requestStatus',
            meal: this.plan,
            silent: true,
          });

          this.$store.dispatch('showError', 'Meal Plan has been edited, resubmit it for review')
        }
        this.close()
      })
    },

    closeDialog() {
      this.dialog = false
    },

    showDialog() {
      this.dialog = true

      setTimeout(async () => {

        if(this.lastOpenMeal == null || this.lastOpenMeal != this.cell.meal) {
          
          this.gettingRecipes = true

          this.lastOpenMeal = this.cell.meal

          this.pageNumber = 1

          this.$store.commit('recipes/setLastVisible', null)

          if (this.cell.meal == 'desert') this.cell.meal = 'dessert'

          // this.getRecipesByMeal(capitalize(this.cell.meal))
          this.getMyRecipes()
          this.getSavedRecipes(this.user)
          this.getAllNutritionists()
          this.getAllNaturopaths()

          let meal = this.cell.meal
          if (meal.includes('snack')) meal = 'snack';

          await getFunc({ meal : capitalize(meal) })
          .then((res) => {
            this.$store.commit('recipes/setRecipesByMeal', res.data)
          })
          .finally(() => {
            setTimeout(() => {
              this.gettingRecipes = false
            }, 800)
          })
        }
      }, 500)

    },
    closeAllRecipeDialog () {
      this.showConfirmDialog = false
      this.allRecipeCheckbox = false
    },
    showAllRecipes () {
      this.loadingAllRecipes = true
      this.showAllRecipesBol = true

      setTimeout(() => {
        this.loadingAllRecipes = false
        this.showConfirmDialog = false
      }, 2000)
    },
    loadMoreRecipes() {
      if(this.currentPageCount <= this.pageNumber)
        this.pageNumber++
    },
    getDietitianId(name) {
      const found = this.dietitians.find(dietitian => {
        return `${dietitian.firstName} ${dietitian.lastName}`.toLowerCase().includes(name.toLowerCase())
      })

      return found.id;
    },
    getNutritionistId(name) {
      const found = this.nutritionists.find(nutritionist => {
        return `${nutritionist.firstName} ${nutritionist.lastName}`.toLowerCase().includes(name.toLowerCase())
      })

      return found.id;
    },
    getNaturopathId(name) {
      const found = this.naturopaths.find(naturopath => {
        return `${naturopath.firstName} ${naturopath.lastName}`.toLowerCase().includes(name.toLowerCase())
      })

      return found.id;
    },
    setView(view) {
      this.view = view
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    setTimeout(() => {
      if (!this.nutritionTags.length) this.getTags()
      if(!this.companies.length) this.getCompanies()
      // this.getAllDietitians()
    }, 500)
  },
}
</script>
<style scoped>

.custom-badge {
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  line-height: 1.4;
  height: 14px;
  width: 14px;
}

</style>