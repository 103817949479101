<template>
<v-container fluid>
  <v-row>
    <v-col sm="12" md="6" lg="6" xl="6" class="py-0 mb-5 mb-lg-0">
      <recent-users />
    </v-col>
    <v-col sm="12" md="6" lg="6" xl="6"  class="py-0">
      <recent-recipes />
    </v-col>
  </v-row>

  <v-row class="mt-5">
    <v-col cols="12">
      <Subscriptions/>
    </v-col>
  </v-row>
</v-container>
</template>

<script>

import RecentUsers from  '@/components/RecentUsers'
import RecentRecipes from  '../recipe/RecentRecipes'
import Subscriptions from  '@/components/Subscriptions'

export default {
  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecentUsers,
    RecentRecipes,
    Subscriptions,
  }
}
</script>