<template>
  <div>
    <div v-show="!viewOnly">
      <div
        v-if="!status.getting && !plans?.length && currentFolder?.client"
      >
        <v-alert
          type="info"
          border="left"
          text
          v-if="
            $store.getters['user/mealPlans'] > 0 ||
            $store.getters['user/mealPlans'] == -1
          "
        >
          <span
            class="mr-2"
            >You don't have any
            <strong
              ><u>{{ isOnMealPlanRoute ? "Meal Plans" : "Recipe eBooks" }}</u></strong
            >
            yet for this client</span
          >
          <v-btn
            v-show="currentFolder?.id != '1'"
            v-if="
              $store.getters['user/mealPlans'] > 0 ||
              $store.getters['user/mealPlans'] == -1
            "
            color="primary"
            small
            @click="showCalculator(false)"
          >
            Add a new {{ isOnMealPlanRoute ? "Meal Plan" : "Recipe eBook" }}
          </v-btn>
        </v-alert>
      </div>
      <v-alert v-if="$store.getters['user/mealPlans'] == 0" type="warning" border="left" text>
        <span class="mr-2"
        >
        You have reached your maximum number of meal plans and recipe eBooks for the month. To increase your limit, please click here to upgrade your subscription
        </span>
        <v-btn
          color="primary"
          small
          depressed
          :to="{ name: 'Subscription' }"
        >
        Upgrade Subscription
        </v-btn>
      </v-alert>

      <!-- <div v-if="!plan && !plans?.length">
        <span class="grey--text caption" v-if="subscription || user.mealSlots">
          {{ allPlans?.length }} out of
          {{
            $store.getters["user/mealPlans"] === -1
              ? "Unlimited"
              : $store.getters["user/mealPlans"]
          }}
          Meal Plan Storage Capacity
        </span>
      </div> -->

      <div v-if="user && plan && plans?.length">
        <v-alert
          v-if="!goal && !$store.state.goal.status.getting"
          type="warning"
          border="left"
          text
        >
          Please complete the meal plan details before generating a meal plan.
        </v-alert>

        <v-alert type="warning" border="left" text v-if="plan?.noMeals">
          There are not enough recipes to meet the requested criteria. This can occur if
          there is a relatively high-calorie target with limited meals selected in the
          meal pattern. Please consider adding more meals or snacks, or adjusting the
          nutrition tags, meal tags or excluded ingredients. If this does not work, please
          email
          <a href="mailTo:hello@mealzee.com">hello@mealzee.com</a> and we will investigate
        </v-alert>
        <br v-if="plan?.noMeals" />

        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <div class="d-flex gap-1 align-center flex-wrap flex-lg-nowrap flex-xl-nowrap">
          <div class="col-sm-12 col-lg-2">
            <v-card
              class="mt-5 mb-3 box-shadow pa-5 mealplan-container arrow-container"
              :class="isOnMealPlanRoute ? 'custom-purple' : 'primary'"
              outlined
            >
              <!-- <span class="mealplan-names primary">Current Meal Plan:</span> -->
              <div class="d-flex flex-column mb-3">
                <div class="pa-0">
                  <span class="white--text caption">You are currently on Meal Plan:</span>
                </div>
                <div
                  class="d-flex gap-1 px-10 flex-column align-center justify-center w-100"
                >
                  <img src="/svg/mealplan.svg" class="plan-icon large" />
                  <div class="current-plan-info">
                    <div class="white--text caption text-center mb-3">
                      <strong>{{ plan.name }}</strong>
                    </div>
                    <div class="d-flex justify-center" v-if="currentFolder?.id != '1'">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="white info--text"
                            class="mr-2"
                            depressed
                            x-small
                            @click="
                              plan?.requestStatus == 'approved'
                                ? (reqWarning = true)
                                : showCalculator(true)
                            "
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit this Meal Plan</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="white red--text"
                            x-small
                            @click="$emit('delete', plan)"
                            depressed
                          >
                            <v-icon small>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete this Meal Plan</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bottom-arrow1"
                :class="isOnMealPlanRoute ? 'green' : 'blue'"
              ></div>
            </v-card>
          </div>
          <div class="col-sm-12 col-lg-10">
            <v-card
              :class="isOnMealPlanRoute ? 'custom-purple' : 'primary'"
              class="mt-5 mb-3 box-shadow pa-5 mealplan-container"
              outlined
            >
              <span class="mealplan-names"
                >{{ isOnMealPlanRoute ? "Meal Plans" : "Recipe eBooks" }} in this
                folder:</span
              >
              <v-row>
                <div class="d-flex gap-10 pa-0 align-center justify-start">
                  <v-text-field
                    v-model="searchPlan"
                    hide-details
                    clearable
                    outlined
                    dense
                    background-color="white"
                    placeholder="Search Meal Plan"
                  ></v-text-field>
                  <v-btn
                    v-if="
                      $store.getters['user/mealPlans'] > 0 ||
                      $store.getters['user/mealPlans'] == -1
                    "
                    v-show="currentFolder?.id != '1'"
                    @click="showCalculator(false)"
                    color="white primary--text"
                    class="mr-2 step-add-new"
                    data-step="6"
                    small
                  >
                    <v-icon left>mdi-plus-thick</v-icon>
                    Add a new {{ isOnMealPlanRoute ? "Meal Plan" : "Recipe eBook" }}
                  </v-btn>
                  <!-- <v-btn @click="copyLink(generatedLink)" color="primary" small>
                    <v-icon small class="mr-1">mdi-content-copy</v-icon>
                    Client Questionnaire
                  </v-btn> -->
                  <!-- <span class="white--text caption" v-if="subscription || user.mealSlots">
                    {{ allPlans?.length }} out of
                    {{
                      $store.getters["user/mealPlans"] === -1
                        ? "Unlimited"
                        : $store.getters["user/mealPlans"]
                    }}
                    Meal Plan Storage Capacity
                  </span> -->
                </div>
                <v-col cols="12">
                  <div class="mr-2 mb-3" data-step="7">
                    <v-row class="mb-3">
                      <v-col cols="12" class="pa-0">
                        <span class="white--text caption"
                          >Choose a
                          {{ isOnMealPlanRoute ? "Meal Plan" : "Recipe eBook" }}:</span
                        >
                      </v-col>
                    </v-row>
                    <v-slide-group show-arrows center-active v-model="planSlide">
                      <div class="d-flex gap-5 justify-center align-center w-100">
                        <v-slide-item
                          v-for="p in plans.filter(
                            (p) =>
                              !searchPlan ||
                              p.name
                                .toLowerCase()
                                .includes(searchPlan && searchPlan.toLowerCase())
                          )"
                          :key="p?.id"
                          v-slot="{ toggle }"
                        >
                          <div
                            :class="{
                              'selected-plan': plan?.id == p.id,
                              reverse: !isOnMealPlanRoute,
                            }"
                            class="plan-container-info px-5"
                            @click="
                              () => {
                                toggle();
                                changePlan(p);
                              }
                            "
                          >
                            <img src="/svg/mealplan.svg" class="plan-icon" />
                            <div style="height: 10px" />
                            <div class="plan-info">
                              <div class="caption plan-info-name">
                                {{
                                  truncate(p.name, {
                                    length: 11,
                                    omission: "..",
                                  })
                                }}
                              </div>
                            </div>
                          </div>
                        </v-slide-item>
                      </div>
                    </v-slide-group>
                    <!-- <div
                  :style="{ lineHeight: '1.1' }"
                  class="title font-weight-bold"
                >
                  <v-btn
                    color="info"
                    x-small
                    @click="
                      plan?.requestStatus == 'approved'
                        ? (reqWarning = true)
                        : showCalculator(true)
                    "
                    icon
                  >
                    <v-icon left>mdi-pencil</v-icon>
                  </v-btn>
                  <span
                    @blur="updateName($event.target.textContent, plan)"
                    contenteditable="true"
                    spellcheck="false"
                    >{{ plan.name }}</span
                  >

                  <v-btn 
                  @click="
                    $copyText(`${$window.origin}/?p=${plan.id}`)
                    .then(() => $store.dispatch('showSuccess', 'URL copied to clipboard'))
                  "
                  class="ml-2" 
                  small icon
                >
                  <v-icon>mdi-link</v-icon>
                </v-btn>

                  <v-menu
                    :close-on-content-click="false"
                    v-if="plans?.length"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-btn v-on="on" class="ml-2" small icon>
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="overflow-hidden">
                      <v-card-title> </v-card-title>
                      <v-card-text
                        :style="{ height: '350px', 'overflow-y': 'scroll' }"
                      >
                        <v-list dense>
                          <v-list-item
                            :disabled="p.id == plan.id"
                            @click="changePlan(p)"
                            :key="p.id"
                          >
                            <v-list-item-title>{{ p.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>

        <v-alert
          v-if="plan?.requestStatus == 'rejected'"
          type="warning"
          border="left"
          text
        >
          <span class="mr-2"
            >Your request for an approval stamp has been rejected with the reason:</span
          >
          <div v-html="plan?.rejectReason" />
        </v-alert>

        <v-alert
          v-if="plan?.requestStatus == 'requesting'"
          type="info"
          border="left"
          text
        >
          <span class="mr-2">
            Your Meal Plan review is in progress, we will update you as soon as possible!
          </span>
        </v-alert>

        <v-alert
          v-if="plan?.requestStatus == 'approved'"
          type="success"
          border="left"
          text
        >
          <span class="mr-2">This Meal Plan has been Dietitian Approved.</span>
        </v-alert>

        <v-alert v-if="plan?.requestStatus == 'edited'" type="warning" border="left" text>
          <span class="mr-2"
            >This Meal Plan has been edited, you need to resubmit it for review.</span
          >
        </v-alert>

        <v-card class="my-5 box-shadow pa-5 folder-container" outlined>
          <div class="d-flex gap-20 justify-start align-start flex-wrap flex-lg-nowrap flex-xl-nowrap">
            <div class="border-right">
              <p class="mb-1">Filters:</p>
              <div style="width: 100%">
                <Tags
                  :customModel="
                    [
                      'dietitian',
                      'personal_trainer',
                      'nutritionist',
                      'naturopath',
                    ].includes(user.role)
                      ? plan
                      : user
                  "
                  class="step-meal-tags px-3 pt-1"
                  v-if="goal"
                  tagViewProp="meal"
                />

              </div>
            </div>
            <div>
              <p class="mb-1">Editing Tools:</p>
              <div class="d-flex gap-5 justify-start align-center flex-wrap mb-3">
                <v-btn
                  class="info--text"
                  @click="unlockAll()"
                  :loading="unlocking"
                  :disabled="!goal || locking"
                  :class="!goal ? 'invisible' : ''"
                  color="white"
                  v-if="meals?.length"
                  small
                >
                  <v-icon left>mdi-lock-open</v-icon>
                  Unlock All
                </v-btn>

                <v-btn
                  class="info--text"
                  @click="lockAll()"
                  :loading="locking"
                  :disabled="!goal || unlocking"
                  :class="!goal ? 'invisible' : ''"
                  color="white"
                  v-if="meals?.length"
                  small
                >
                  <v-icon left>mdi-lock</v-icon>
                  Lock All
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="moveDialog = true"
                      class="info--text"
                      color="white"
                      v-on="on"
                      small
                      :disabled="!$store.getters['subscriptions/subscribed']"
                    >
                      <v-icon left>mdi-file-move-outline</v-icon>
                      Move
                    </v-btn>
                  </template>
                  <span>Move Plan to another Folder</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="duplicateDialog = true"
                      class="info--text"
                      color="white"
                      v-on="on"
                      small
                      :disabled="!$store.getters['subscriptions/subscribed']"
                    >
                      <v-icon left>mdi-content-copy</v-icon>
                      Duplicate
                    </v-btn>
                  </template>
                  <span>Duplicate this Mealplan</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-show="isOnMealPlanRoute"
                      @click="convertPlan"
                      :loading="converting"
                      class="info--text"
                      color="white"
                      v-on="on"
                      small
                      :disabled="!$store.getters['subscriptions/subscribed']"
                    >
                      <v-icon left>mdi-file-sync</v-icon>
                      Copy to Recipe eBooks
                    </v-btn>
                  </template>
                  <span>Copy Meal plan to Recipe plan</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-show="!isOnMealPlanRoute"
                      @click="convertPlan"
                      :loading="converting"
                      class="info--text"
                      color="white"
                      v-on="on"
                      small
                      :disabled="!$store.getters['subscriptions/subscribed']"
                    >
                      <v-icon left>mdi-file-sync</v-icon>
                      Copy to Meal Plan
                    </v-btn>
                  </template>
                  <span>Copy Recipe plan to Meal plan</span>
                </v-tooltip>
              </div>
              <div v-show="goal">
                <p class="mb-1">Choose Recipes by Nutrition Profession:</p>
                <v-btn-toggle color="primary" mandatory class="mb-5" style="flex-wrap: wrap">
                  <v-btn @click="setRoleType('all')" x-small>All</v-btn>
                  <v-btn @click="setRoleType('dietitian')" x-small>Dietitians</v-btn>
                  <v-btn @click="setRoleType('nutritionist')" x-small
                    >Nutritionists</v-btn
                  >
                  <v-btn @click="setRoleType('naturopath')" x-small>Naturopaths</v-btn>
                </v-btn-toggle>
              </div>
            </div>
            <div>
              <p class="mb-1" v-if="meals?.length && user.role != 'member'">
                Publishing Tools:
              </p>
              <div class="d-flex gap-5 justify-start align-center flex-wrap mb-3">
                <v-btn
                  class="mt-2"
                  @click="copyRecipeLink()"
                  color="primary"
                  x-small
                  depressed
                  v-if="!isOnMealPlanRoute"
                  v-show="plan.linkDetails"
                  :disabled="!$store.getters['subscriptions/subscribed']"
                >
                  <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                  Recipe Ebook Link
                </v-btn>
                <v-btn
                  class="mt-2"
                  v-if="isOnMealPlanRoute"
                  v-show="plan.mealplanLink"
                  @click="copyLink(plan.mealplanLink)"
                  color="primary"
                  x-small
                  depressed
                  :disabled="!$store.getters['subscriptions/subscribed']"
                >
                  <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                  Meal Plan Ebook Link
                </v-btn>

                <div class="d-flex gap-8 flex-wrap">
                  <v-btn
                    @click="
                      $store.getters['subscriptions/subscribed']
                        ? (confirmGenerate = true)
                        : $root.$emit('showSubscribeDialog')
                    "
                    class="mr-2 info--text"
                    :disabled="
                      !goal || !$store.getters['subscriptions/subscribed']
                    "
                    color="white"
                    v-if="
                      meals?.length &&
                      user.role != 'member' &&
                      !isOnMealPlanRoute &&
                      !plan.autogen
                    "
                    :loading="generatingLink"
                    small
                  >
                    <v-icon left>mdi-link-variant-plus</v-icon>
                    Generate Recipe eBook Link
                  </v-btn>
                  <!-- <v-btn
                    @click="refreshEbook"
                    class="mr-2 info--text"
                    :disabled="
                      !goal || !$store.getters['subscriptions/isOnModerateOrSuper']
                    "
                    v-if="
                      meals?.length &&
                      user.role != 'member' &&
                      !isOnMealPlanRoute
                    "
                    color="white"
                    :loading="refreshingEbook"
                    small
                  >
                  <v-icon left>mdi-refresh</v-icon>
                  Refresh Ebook
                  </v-btn> -->
                  <v-btn
                    @click="
                      $store.getters['subscriptions/subscribed']
                        ? (confirmGenerateMealplan = true)
                        : $root.$emit('showSubscribeDialog')
                    "
                    class="mr-2 primary--text"
                    :disabled="
                      !goal || !$store.getters['subscriptions/subscribed']
                    "
                    color="white"
                    v-if="meals?.length && user.role != 'member' && isOnMealPlanRoute"
                    :loading="generatingLink"
                    small
                  >
                    <v-icon left>mdi-link-variant-plus</v-icon>
                    Generate Meal Plan eBook Link
                  </v-btn>
                  <v-menu
                    offset-y
                    v-if="meals?.length && user.role != 'member' && isOnMealPlanRoute"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="info" dark v-bind="attrs" v-on="on" small :disabled="!$store.getters['subscriptions/subscribed']">
                        PDF Meal Plan
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="meals?.length"
                        v-show="goal"
                        @click="showShoppingListDialog"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon left>mdi-basket</v-icon>
                            Shopping List</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="meals?.length"
                        v-show="goal"
                        @click="
                          $store.getters['subscriptions/subscribed']
                            ? startDownloadRecipes(0)
                            : $root.$emit('showSubscribeDialog')
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon left>mdi-download-multiple</v-icon>
                            Recipes</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="meals?.length"
                        v-show="goal"
                        @click="mealPlanDownload"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon left>mdi-download</v-icon>
                            Meal Plan</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <p class="mb-1" v-if="meals?.length && user.role != 'member'">
                Other Tools:
              </p>
              <div class="d-flex gap-5 justify-start align-center flex-wrap">
                <v-btn
                  color="white primary--text"
                  v-if="!plan.addedToMarketplace && meals?.length"
                  :disabled="!goal || !$store.getters['subscriptions/subscribed']"
                  @click="$emit('addToMarketplace')"
                  class="mr-2"
                  :class="!goal ? 'invisible' : ''"
                  small
                >
                  <v-icon left>mdi-store-plus</v-icon>
                  Add to Marketplace
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <!-- <v-btn
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      @click="requestDialog = true"
                      :loading="requesting"
                      color="warning"
                      v-on="on"
                      v-if="plan?.requestStatus == 'rejected'"
                      small
                    > -->
                    <v-btn
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      @click="requestDialog = true"
                      :loading="requesting"
                      color="warning"
                      v-on="on"
                      v-if="plan?.requestStatus == 'rejected'"
                      v-show="user.role == 'personal_trainer' && meals?.length"
                      small
                    >
                      <v-icon left>mdi-note-plus</v-icon>
                      Resubmit Request
                    </v-btn>
                    <!-- <v-btn
                      v-else
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      @click="requestDialog = true"
                      :disabled="
                        plan?.requestStatus == 'requesting' ||
                        plan?.requestStatus == 'approved'
                      "
                      :loading="requesting"
                      color="info"
                      v-on="on"
                      small
                    > -->
                    <v-btn
                      v-else
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      @click="requestDialog = true"
                      :disabled="
                        plan?.requestStatus == 'requesting' ||
                        plan?.requestStatus == 'approved' ||
                        !$store.getters['subscriptions/subscribed']
                      "
                      :loading="requesting"
                      color="info"
                      v-on="on"
                      v-show="user.role == 'personal_trainer' && meals?.length"
                      small
                    >
                      <v-icon left>mdi-note-plus</v-icon>
                      REQUEST DIETITIAN REVIEW
                    </v-btn>
                  </template>
                  <span>Click here to request a dietitian review of your meal plan</span>
                </v-tooltip>

              </div>
            </div>
          </div>
        </v-card>

        <v-card class="mb-5 box-shadow" outlined>
          <div class="pa-5">
            <v-row>
              <v-col cols="12" class="pa-0 ml-3" v-if="plan.link || plan.mealplanLink">
                <div class="d-flex justify-start align-center" style="gap: 0.5rem">
                  <!-- <div class="text-body-2">Ebook link:</div> -->
                  <!-- <div class="d-inline-block text-truncate">
                  <a :href="plan.link"  target="_blank" >Ebook link</a>
                </div> -->
                </div>
              </v-col>
              <v-col cols="12" style="display: flex; flex-wrap: wrap; gap: 0.6rem">
                <v-btn
                  color="white warning--text"
                  class="mr-2"
                  v-if="plan.addedToMarketplace && meals?.length"
                  :disabled="!goal"
                  :class="!goal ? 'invisible' : ''"
                  @click="$emit('showRemoveMarketplace')"
                  small
                >
                  <v-icon left>mdi-store-minus</v-icon>
                  Remove from Marketplace
                </v-btn>

                <!-- <v-btn
                @click="$store.getters['subscriptions/subscribed'] ? dlShoppingList() : $root.$emit('showSubscribeDialog')"
                :loading="downloadingShoppingList"
                class="mr-2 info--text"
                :disabled="!goal"
                color="white"
                small
              >
                <v-icon left>mdi-basket</v-icon>
                Shopping List
              </v-btn> -->

                <!-- <v-btn
                  @click="showShoppingListDialog"
                  class="mr-2 info--text"
                  :disabled="!goal || !plan.hasDownloaded"
                  :class="!goal ? 'invisible' : ''"
                  color="white"
                  small
                  v-if="meals?.length"
                >
                  <v-icon left>mdi-basket</v-icon>
                  Shopping List
                </v-btn> -->

                <!-- <v-btn
                  @click="
                    $store.getters['subscriptions/subscribed']
                      ? startDownloadRecipes(0)
                      : $root.$emit('showSubscribeDialog')
                  "
                  class="mr-2 info--text"
                  :disabled="!goal || !plan.hasDownloaded"
                  :class="!goal ? 'invisible' : ''"
                  color="white"
                  v-if="meals?.length"
                  small
                >
                  <v-icon left>mdi-download-multiple</v-icon>
                  Recipes
                </v-btn> -->

                <!-- <span v-if="!plan.hasDownloaded">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="black"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                        class="mr-2"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span
                      >The Recipe Cards and Shopping List will be activated
                      after downloading the Meal Plan</span
                    >
                  </v-tooltip>
                </span> -->

                <!-- <span
                  v-if="
                    !$store.getters['subscriptions/isOnSuper'] &&
                    user.role != 'member'
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="black"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                        class="mr-2"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>You need to be on a Super plan</span>
                  </v-tooltip>
                </span> -->

                <!-- <v-btn
                  @click="mealPlanDownload"
                  class="mr-2 info--text"
                  :disabled="!goal"
                  :class="!goal ? 'invisible' : ''"
                  color="white"
                  v-if="meals?.length"
                  small
                >
                  <v-icon left>mdi-download</v-icon>
                  Meal Plan
                </v-btn> -->

                <v-btn
                  class="mr-2 step-generate-btn"
                  :class="!goal ? 'invisible' : ''"
                  :loading="status.generating"
                  @click="autoGenerateMethod(plan)"
                  :disabled="!goal"
                  color="primary"
                  data-step="6"
                  small
                >
                  <v-icon left>mdi-autorenew</v-icon>
                  Generate Recipes
                </v-btn>

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="$emit('delete', plan)"
                      color="red"
                      v-on="on"
                      depressed
                      small
                      dark
                      icon
                    >
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Plan</span>
                </v-tooltip> -->
              </v-col>
            </v-row>
          </div>

          <preloader v-if="fetching" message="Please wait..." />

          <!-- IF DIETITIAN OR PERSONAL TRAINER -->
          <v-card
            v-if="
              ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(
                user.role
              ) && !fetching
            "
            outlined
          >
            <v-card-text>
              <div class="d-flex align-center gap-15 flex-wrap flex-lg-nowrap flex-xl-nowrap">
                <div>
                  <v-text-field
                    v-if="user.energyUnit == 'cal'"
                    :value="
                      goal
                        ? $options.filters.numberFormat(
                            $options.filters.kjToCal(goal.customTarget) ||
                              $store.getters['goal/sctbg']
                          )
                        : 0
                    "
                    label="Daily energy limit"
                    hide-details
                    suffix="Cal"
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :value="
                      goal
                        ? $options.filters.numberFormat(
                            goal.customTarget || $store.getters['goal/sktbg']
                          )
                        : 0
                    "
                    label="Daily energy limit"
                    hide-details
                    suffix="kJ"
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <!-- <v-btn @click="$refs.calculatorDialog.showDialog()" color="info--text white" class="font-weight-bold step-energy-btn" data-step="2" >Add Meal Plan Details</v-btn> -->

                <div v-if="!plan.recipeplan">
                  <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                  <div class="d-flex flex-wrap flex-lg-nowrap flex-xl-nowrap gap-15">
                    <v-switch
                      @change="toggleEnergy"
                      label="Hide Nutrition Info"
                      v-model="plan.hideEnergy"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      @change="toggleMealTags"
                      label="Hide Meal Tags"
                      v-model="plan.hideMealTags"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      @change="toggleNutriTags"
                      label="Hide Nutrition Tags"
                      v-model="plan.hideNutriTags"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      v-if="meals?.length"
                      label="Hide Recipe Customization"
                      @change="toggleCustomization"
                      :loading="toggleCustomLoading"
                      v-model="plan.hideCustomization"
                      :disabled="!goal"
                      hide-details=""
                      class="mt-0 pt-0"
                      dense
                    >
                    </v-switch>
                    <!-- <v-btn
                      color="white primary--text"
                      v-if="!plan?.hideCustomization && meals?.length"
                      :disabled="!goal"
                      @click="hideCustomization"
                      :loading="toggleCustomLoading"
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      small
                    >
                      <v-icon left>mdi-eye-off</v-icon>
                      Hide Recipe Customization
                    </v-btn> -->
                    <!-- <v-btn
                      color="white primary--text"
                      v-if="plan?.hideCustomization && meals?.length"
                      :disabled="!goal"
                      @click="revealCustomization"
                      :loading="toggleCustomLoading"
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      small
                    >
                      <v-icon left>mdi-eye</v-icon>
                      Reveal Customization
                    </v-btn> -->
                  </div>
                </div>
                <div v-else>
                  <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                  <div class="d-flex gap-15 flex-wrap flex-lg-nowrap flex-xl-nowrap">
                    <v-switch
                      @change="toggleTags"
                      :value="recipePlanTags.hideEnergy"
                      label="Hide Nutrition Info"
                      v-model="recipePlanTags.hideEnergy"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      @change="toggleTags"
                      label="Hide Meal Tags"
                      :value="recipePlanTags.hideMealTags"
                      v-model="recipePlanTags.hideMealTags"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      @change="toggleTags"
                      :value="recipePlanTags.hideNutriTags"
                      label="Hide Nutrition Tags"
                      v-model="recipePlanTags.hideNutriTags"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      label="Hide Recipe Customization"
                      @change="toggleTags"
                      v-model="recipePlanTags.hideCustomization"
                      :disabled="!goal"
                      hide-details=""
                      class="mt-0 pt-0"
                      dense
                    >
                    </v-switch>
                    <!-- <v-btn
                      color="white primary--text"
                      v-if="!plan?.hideCustomization && meals?.length"
                      :disabled="!goal"
                      @click="hideCustomization"
                      :loading="toggleCustomLoading"
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      small
                    >
                      <v-icon left>mdi-eye-off</v-icon>
                      Hide Recipe Customization
                    </v-btn> -->
                    <!-- <v-btn
                      color="white primary--text"
                      v-if="plan?.hideCustomization && meals?.length"
                      :disabled="!goal"
                      @click="revealCustomization"
                      :loading="toggleCustomLoading"
                      class="mr-2"
                      :class="!goal ? 'invisible' : ''"
                      small
                    >
                      <v-icon left>mdi-eye</v-icon>
                      Reveal Customization
                    </v-btn> -->
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-simple-table id="mealplan" v-if="!fetching" class="bordered">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(day, i) in 7"
                    :key="i"
                    :class="checkCurrent(i) ? 'primary' : 'dark lighten-1'"
                    class="white--text text-center"
                  >
                    <span class="body-1" v-if="isOnMealPlanRoute">
                      {{ getDay(day) }}

                      <v-btn @click="lockByDay(day)" class="ml-1" x-small icon
                        ><v-icon x-small>mdi-lock</v-icon></v-btn
                      >
                      <v-btn @click="unlockByDay(day)" class="ml-1" x-small icon
                        ><v-icon x-small>mdi-lock-off</v-icon></v-btn
                      >
                    </span>

                    <span class="body-1" v-else>
                      Option {{ day }}
                      <v-btn @click="lockByDay(day)" class="ml-1" x-small icon
                        ><v-icon x-small>mdi-lock</v-icon></v-btn
                      >
                      <v-btn @click="unlockByDay(day)" class="ml-1" x-small icon
                        ><v-icon x-small>mdi-lock-off</v-icon></v-btn
                      >
                    </span>

                    <!-- <div class="d-flex align-center">
                    <v-avatar
                      size="25"
                      color="dark"
                      class="mr-2"
                      dark
                    >
                      
                    </v-avatar>
                    div class="font-weight-medium d-none">{{ setDate(i) | completeDate }}</div>
                  </div> -->
                  </th>
                </tr>
                <tr>
                  <th
                    colspan="8"
                    style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"
                    class="font-weight-bold body-1 primary--text"
                  >
                    Main Meals
                  </th>
                </tr>
              </thead>
              <tbody v-if="goal">
                <tr v-for="(meal, i) in mainMeals" :key="i">
                  <td
                    :style="{
                      border: !plan[`serving_${meal}`] ? '1px red solid' : '',
                    }"
                  >
                    <v-subheader class="step-meal-servings mb-3" data-step="5">
                      <div
                        class="flex flex-column align-center justify-center text-center"
                      >
                        <div>
                          {{ meal == "desert" ? "dessert" : meal | capitalize }}
                          {{ getAlternateWord(meal) }}
                        </div>
                        <div class="text-center" style="min-width: 100px">
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="info"
                                v-if="currentFolder?.id != '1'"
                                depressed
                                icon
                                x-small
                                @click="openAlternateDialog(meal)"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Set an alternate name</span>
                          </v-tooltip>
                          <v-btn @click="lockByMealTime(meal)" class="ml-1" x-small icon
                            ><v-icon x-small>mdi-lock</v-icon></v-btn
                          >
                          <v-btn @click="unlockByMealTime(meal)" class="ml-1" x-small icon
                            ><v-icon x-small>mdi-lock-off</v-icon></v-btn
                          >
                        </div>
                      </div>
                    </v-subheader>

                    <v-subheader
                      v-if="!plan[`serving_${meal}`]"
                      class="red--text text--darken-1 text-center"
                      :style="{ lineHeight: 0.9, fontSize: '11px', padding: 0 }"
                      >Please put a serving value</v-subheader
                    >
                    <div class="step-serving-number" data-step="6">
                      <v-text-field
                        @input="
                          updateMealField({
                            meal: plan,
                            field: `serving_${meal}`,
                            value: plan[`serving_${meal}`],
                          })
                        "
                        v-model.number="plan[`serving_${meal}`]"
                        class="caption mx-auto text-center"
                        suffix="servings"
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                  </td>
                  <td
                    v-for="i in 7"
                    @drop="
                      plan?.requestStatus == 'approved'
                        ? confirmDrop({
                            meal,
                            day: i,
                            plan,
                            id: hasMeal(meal, i) ? hasMeal(meal, i).id : null,
                          })
                        : dropped({
                            meal,
                            day: i,
                            plan,
                            id: hasMeal(meal, i) ? hasMeal(meal, i).id : null,
                          })
                    "
                    :style="{ verticalAlign: 'top' }"
                    @dragenter.prevent
                    @dragover.prevent
                    class="pa-0"
                    width="13%"
                    :key="i"
                  >
                    <!-- <v-overflow-btn
                        v-show="!hasMeal(meal, i) || hasMeal(meal, i) == 'no meal'"
                        @input="addMealPlan($event, meal, i)"
                        :items="['find a meal', 'no meal']"
                        :value="hasMeal(meal, i) || null"
                        style="border: none !important;"
                        class="fill-height ma-0 d-none"
                        :ref="`${meal}-${i}`"
                        label="Select"
                        hide-details
                        clearable
                        block
                        small
                      ></v-overflow-btn> -->

                    <div
                      v-if="!hasMeal(meal, i)"
                      class="fill-height d-flex justify-center align-center flex-column gap-5"
                    >
                      <v-btn
                        @click="addMealPlan('find a meal', meal, i)"
                        color="info"
                        x-small
                        outlined
                        >Find a meal</v-btn
                      >
                      <v-btn
                        v-if="recentlyDeletedMeal(meal, i)"
                        @click="undoDelete(meal, i)"
                        color="warning"
                        outlined
                        x-small
                        >Undo delete</v-btn
                      >
                    </div>

                    <div
                      v-if="hasMeal(meal, i) && hasMeal(meal, i) !== 'no meal'"
                      :draggable="parseInt(hasMeal(meal, i).serves) > 1"
                      @dragstart="itemDragged = hasMeal(meal, i)"
                    >
                      <TableMeal
                        @remove="removeMeal(meal, i, $event)"
                        @showCard="showCardDialog"
                        :meal="hasMeal(meal, i)"
                        :mealType="meal"
                        :plan="plan"
                        :day="i"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    colspan="8"
                    style="
                      border-bottom: thin solid rgba(0, 0, 0, 0.12);
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                    "
                    class="font-weight-bold body-1 accent--text"
                  >
                    Snacks
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="snack in goal ? goal.snacks : 0" :key="snack">
                  <td
                    :style="{
                      border: !plan[`serving_snack${snack}`] ? '1px red solid' : '',
                    }"
                    class="font-weight-bold"
                  >
                    <v-subheader class="mb-3">
                      <div
                        class="flex flex-column align-center justify-center text-center"
                      >
                        <div>
                          Snack {{ snack }}
                          {{ getAlternateWord(`snack${snack}`) }}
                        </div>
                        <div class="text-center" style="min-width: 100px">
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="info"
                                class="mr-2"
                                v-if="currentFolder?.id != '1'"
                                depressed
                                icon
                                x-small
                                @click="openAlternateDialog(`snack${snack}`)"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Set an alternate name</span>
                          </v-tooltip>
                          <v-btn
                            @click="lockByMealTime(`snack${snack}`)"
                            class="ml-1"
                            x-small
                            icon
                            ><v-icon x-small>mdi-lock</v-icon></v-btn
                          >
                          <v-btn
                            @click="unlockByMealTime(`snack${snack}`)"
                            class="ml-1"
                            x-small
                            icon
                            ><v-icon x-small>mdi-lock-off</v-icon></v-btn
                          >
                        </div>
                      </div>
                    </v-subheader>
                    <v-subheader
                      v-if="!plan[`serving_snack${snack}`]"
                      class="red--text text--darken-1 text-center"
                      :style="{ lineHeight: 0.9, fontSize: '11px', padding: 0 }"
                      >Please put a serving value</v-subheader
                    >
                    <div>
                      <v-text-field
                        @input="
                          updateMealField({
                            meal: plan,
                            field: `serving_snack${snack}`,
                            value: plan[`serving_snack${snack}`],
                          })
                        "
                        v-model.number="plan[`serving_snack${snack}`]"
                        class="caption mx-auto text-center"
                        suffix="servings"
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                  </td>
                  <td
                    v-for="i in 7"
                    @drop="
                      plan?.requestStatus == 'approved'
                        ? confirmDrop({
                            meal: `snack${snack}`,
                            day: i,
                            plan,
                            id: hasMeal(`snack${snack}`, i)
                              ? hasMeal(`snack${snack}`, i).id
                              : null,
                          })
                        : dropped({
                            meal: `snack${snack}`,
                            day: i,
                            plan,
                            id: hasMeal(`snack${snack}`, i)
                              ? hasMeal(`snack${snack}`, i).id
                              : null,
                          })
                    "
                    :class="{
                      'red lighten-5': hasMeal(`snack${snack}`, i) == 'no meal',
                    }"
                    :style="{ verticalAlign: 'top' }"
                    @dragenter.prevent
                    @dragover.prevent
                    class="pa-0"
                    :key="i"
                  >
                    <!-- <v-overflow-btn
                        v-show="!hasMeal(`snack${snack}`, i) || hasMeal(`snack${snack}`, i) == 'no meal'"
                        @input="addMealPlan($event, `snack${snack}`, i)"
                        :value="hasMeal(`snack${snack}`, i) || null"
                        :items="['find a meal', 'no meal']"
                        style="border: none !important;"
                        :ref="`snack${snack}-${i}`"
                        class="fill-height ma-0 d-none"
                        label="Select"
                        hide-details
                        block
                        small
                      ></v-overflow-btn> -->

                    <div
                      v-if="!hasMeal(`snack${snack}`, i)"
                      class="fill-height d-flex justify-center align-center"
                    >
                      <v-btn
                        @click="addMealPlan('find a meal', `snack${snack}`, i)"
                        color="info"
                        x-small
                        outlined
                        >Find a meal</v-btn
                      >
                    </div>

                    <div
                      v-if="
                        hasMeal(`snack${snack}`, i) &&
                        hasMeal(`snack${snack}`, i) !== 'no meal'
                      "
                      @dragstart="itemDragged = hasMeal(`snack${snack}`, i)"
                      draggable="true"
                    >
                      <TableMeal
                        v-if="
                          hasMeal(`snack${snack}`, i) &&
                          hasMeal(`snack${snack}`, i) !== 'no meal'
                        "
                        @remove="removeMeal(`snack${snack}`, i, $event)"
                        :meal="hasMeal(`snack${snack}`, i)"
                        :mealType="`snack${snack}`"
                        @showCard="showCardDialog"
                        :day="i"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold dark white--text">Total Energy</td>
                  <td v-for="i in 7" :key="i" class="grey lighten-4 font-weight-bold">
                    <span v-if="user.energyUnit == 'cal'"
                      >{{
                        getTotalNutrientValue(i, "energy")
                          | kjToCal
                          | amount
                          | numberFormat
                      }}
                      Cal</span
                    >
                    <span v-else
                      >{{
                        getTotalNutrientValue(i, "energy") | amount | numberFormat
                      }}
                      kJ</span
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card v-if="!fetching" class="box-shadow" outlined>
          <v-simple-table class="bordered" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Weight (g)</th>
                  <th>Energy {{ user.energyUnit == "cal" ? "(Cal)" : "(kJ)" }}</th>
                  <th>Protein (g)</th>
                  <th>Fat (g)</th>
                  <th>Carbohydrate (g)</th>
                  <th>Sugar (g)</th>
                  <th>Fibre (g)</th>
                  <th>Sodium (mg)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(day, i) in [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                    'Average per Day',
                  ]"
                  :key="i"
                >
                  <td
                    :class="{
                      'font-weight-bold grey lighten-3': day == 'Average per Day',
                    }"
                  >
                    {{ day }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{ getTotalNutrientValue(i + 1, "weight") | amount | numberFormat }}
                  </td>
                  <td
                    v-if="user.energyUnit == 'cal'"
                    width="10%"
                    :class="{ 'font-weight-bold grey lighten-3': i == 7 }"
                  >
                    {{
                      getTotalNutrientValue(i + 1, "energy")
                        | kjToCal
                        | amount
                        | numberFormat
                    }}
                  </td>
                  <td
                    v-else
                    width="10%"
                    :class="{ 'font-weight-bold grey lighten-3': i == 7 }"
                  >
                    {{ getTotalNutrientValue(i + 1, "energy") | amount | numberFormat }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{ getTotalNutrientValue(i + 1, "protein") | amount | numberFormat }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{ getTotalNutrientValue(i + 1, "fat") | amount | numberFormat }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{
                      getTotalNutrientValue(i + 1, "carbohydrate") | amount | numberFormat
                    }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{ getTotalNutrientValue(i + 1, "sugar") | amount | numberFormat }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{ getTotalNutrientValue(i + 1, "fibre") | amount | numberFormat }}
                  </td>
                  <td width="10%" :class="{ 'font-weight-bold grey lighten-3': i == 7 }">
                    {{ getTotalNutrientValue(i + 1, "sodium") | amount | numberFormat }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="font-weight-bold grey lighten-3"
                    :style="{ borderTop: '1px solid #E0E0E0 !important' }"
                    colspan="3"
                  >
                    Macros
                  </td>
                  <td
                    class="font-weight-bold grey lighten-3"
                    :style="{ borderTop: '1px solid #E0E0E0 !important' }"
                  >
                    {{
                      ((getTotalNutrientValue(8, "protein") * 16.7) /
                        getTotalNutrientValue(8, "energy"))
                        | percent
                    }}
                  </td>
                  <td
                    class="font-weight-bold grey lighten-3"
                    :style="{ borderTop: '1px solid #E0E0E0 !important' }"
                  >
                    {{
                      ((getTotalNutrientValue(8, "fat") * 37.7) /
                        getTotalNutrientValue(8, "energy"))
                        | percent
                    }}
                  </td>
                  <td
                    class="font-weight-bold grey lighten-3"
                    :style="{ borderTop: '1px solid #E0E0E0 !important' }"
                  >
                    {{
                      ((getTotalNutrientValue(8, "carbohydrate") * 16.7) /
                        getTotalNutrientValue(8, "energy"))
                        | percent
                    }}
                  </td>
                  <td
                    class="font-weight-bold grey lighten-3"
                    :style="{ borderTop: '1px solid #E0E0E0 !important' }"
                    colspan="3"
                  ></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <!-- DOWNLOAD MEALPLAN -->
        <download-mealplan
          v-if="generatePdf"
          :user="user"
          :mainMeals="mainMeals"
          :meals="meals"
          :goal="goal"
          :rcps="recipes"
          :myRecipes="myRecipes"
          :plan="plan"
          :name="plan.name"
          @downloading="downloadingPDF"
          @beforeDownload="beforeDownload"
          @downloadFinish="downloadPDFFinish"
        />

        <!-- FIND MEAL DIALOG -->
        <FindMeal
          :tagsFilter="
            ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(
              user.role
            )
              ? plan
              : user
          "
          @showCard="showCardDialog"
          ref="findMealDialog"
          :plan="plan"
          :cell="cell"
        />

        <!-- RECIPE CARD DIALOG -->
        <RecipeCard
          @cancel="showCard = false"
          @showOriginalRecipe="showOriginalRec"
          :mealPrep="mealPrep"
          :recipe="recipe"
          :serves="serves"
          :show="showCard"
          :cell="cell_1"
          :meal="meal"
          :hasDownloaded="plan.hasDownloaded"
          v-if="recipe"
          rate
        />

        <!-- CONFIRM DELETE -->
        <ConfirmDelete
          @confirmed="deleteConfirmed()"
          @cancel="showDelete = false"
          :deleting="status.deleting"
          :showDialog="showDelete"
          :message="deleteMessage"
        />

        <!-- SHOPPING LIST DIALOG -->
        <shopping-list-dialog
          @dlShoppingList="dlShoppingList()"
          @openWarningDialog="openWarningDialog()"
          ref="shoppingList"
          :meals="meals"
          :recipes="[...recipes, ...myRecipes]"
          :user="user"
        />

        <!-- WARNING DIALOG -->
        <warning-dialog ref="warningDialog" :user="user" :allPlans="allPlans" />

        <!-- CONFIRM DOWNLOAD -->
        <v-dialog v-model="confirmDownload" max-width="500">
          <close-button @click="confirmDownload = false" overflow />
          <v-card>
            <v-card-title primary-title> Please confirm </v-card-title>
            <v-card-text>
              <p v-if="parseInt(user.downloadCredits) == -1">
                You have unlimited download credits.
              </p>
              <p v-else-if="user.downloadCredits == 0">
                You don't have any download credits left.
              </p>
              <p v-else>
                Download credits: {{ user.downloadCredits }} <br />
                {{
                  user.downloadCredits == 1
                    ? "This is your last download credit."
                    : "This will use one of your download credits."
                }}
              </p>

              <p v-for="(status, i) in statuses" :key="i">
                {{ status }}
              </p>

              <v-btn
                v-if="user.downloadCredits == 0"
                :to="{ name: 'Subscription' }"
                :loading="downloading"
                color="primary"
                class="mr-2"
                >Buy More Credits</v-btn
              >

              <v-btn
                @click="downloadConfirmed()"
                :loading="downloading"
                color="primary"
                class="mr-2"
                v-else
                >Confirm Download</v-btn
              >

              <v-btn @click="confirmDownload = false" :disabled="downloading" text
                >Cancel</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- CONFIRM OPEN TAB -->
        <v-dialog v-model="confirmOpenTab" max-width="500">
          <close-button @click="confirmOpenTab = false" overflow />
          <v-card>
            <v-card-title primary-title> Please allow </v-card-title>
            <v-card-text>
              <p>Click "Allow" to open another tab for the download to proceed</p>
              <v-btn @click="openTab" color="info" class="mr-2">Allow</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- CONFIRM GENERATE -->
        <v-dialog v-model="confirmGenerate" max-width="700">
          <close-button @click="confirmGenerate = false" overflow />
          <v-card>
            <v-card-title primary-title> Please confirm </v-card-title>
            <v-card-text>
              <div style="max-height: 500px; overflow: auto">
                <p v-if="parseInt(user.downloadCredits) == -1">
                  You have unlimited download credits.
                </p>
                <p v-else-if="user.downloadCredits <= 0">
                  You don't have any download credits left.
                </p>
                <p v-else>
                  Download credits: {{ user.downloadCredits }} <br />
                  {{
                    user.downloadCredits == 1
                      ? "This is your last download credit."
                      : "This will use one of your download credits."
                  }}
                </p>

                <!-- <v-textarea
                outlined
                name="Cover Description"
                label="Cover Description"
                :value="plan.description"
                v-model="plan.description"
              ></v-textarea> -->

                <vue-editor
                  v-model="plan.description"
                  placeholder="Cover Description"
                  class="mb-3"
                />

                <div v-if="plan.link" class="my-5">
                  <h4>Your currently generated Link</h4>
                  <small>*You only generate again if there's an update</small>
                  <div class="d-flex justify-start align-center" style="gap: 0.5rem">
                    <!-- <div class="d-inline-block text-truncate">
                    <a :href="plan.link" target="_blank" >Link</a>
                  </div> -->
                    <v-btn
                      @click="copyLink(plan.link)"
                      color="info"
                      small
                      depressed
                      x-small
                      :disabled="!$store.getters['subscriptions/subscribed']"
                    >
                      <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                      Recipe Ebook Link
                    </v-btn>
                  </div>
                </div>

                <small style="display: block; color: red" v-if="!plan.description"
                  >*please provide a description</small
                >
                <div class="d-flex align-center" style="gap: 0.5rem">
                  <v-btn
                    v-if="user.downloadCredits == 0"
                    :to="{ name: 'Subscription' }"
                    :loading="downloading"
                    color="primary"
                    class="mr-2"
                    >Buy More Credits</v-btn
                  >

                  <v-btn
                    @click="generateLink()"
                    :loading="generatingLink"
                    :disabled="!plan.description"
                    color="primary"
                    class="mr-2"
                    v-else
                    >{{ plan?.link ? "Update Link" : "Generate Link" }}</v-btn
                  >

                  <v-btn @click="confirmGenerate = false" :disabled="generatingLink" text
                    >Cancel</v-btn
                  >
                  <v-switch
                    @change="
                      $store.dispatch('mealplans/updateMealField', {
                        value: plan.hideInstructionsEbook,
                        field: 'hideInstructionsEbook',
                        meal: plan,
                      })
                    "
                    label="Hide Instruction page"
                    v-model="plan.hideInstructionsEbook"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmGenerateMealplan" max-width="700">
          <close-button @click="confirmGenerateMealplan = false" overflow />
          <v-card>
            <v-card-title primary-title> Please confirm </v-card-title>
            <v-card-text>
              <div style="max-height: 500px; overflow: auto">
                <p v-if="parseInt(user.downloadCredits) == -1">
                  You have unlimited download credits.
                </p>
                <p v-else-if="user.downloadCredits <= 0">
                  You don't have any download credits left.
                </p>
                <p v-else>
                  Download credits: {{ user.downloadCredits }} <br />
                  {{
                    user.downloadCredits == 1
                      ? "This is your last download credit."
                      : "This will use one of your download credits."
                  }}
                </p>

                <vue-editor
                  v-model="plan.mealplanDescription"
                  placeholder="Cover Description"
                  class="mb-3"
                />

                <div v-if="plan.mealplanLink" class="my-5">
                  <h4>Your currently generated Link</h4>
                  <small>*You only generate again if there's an update</small>
                  <div class="d-flex justify-start align-center" style="gap: 0.5rem">
                    <!-- <div class="d-inline-block text-truncate">
                    <a :href="plan.link" target="_blank" >Link</a>
                  </div> -->
                    <v-btn
                      @click="copyLink(plan.mealplanLink)"
                      color="info"
                      small
                      depressed
                      x-small
                      :disabled="!$store.getters['subscriptions/subscribed']"
                    >
                      <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                      Meal Plan Ebook Link
                    </v-btn>
                  </div>
                </div>

                <small style="display: block; color: red" v-if="!plan.mealplanDescription"
                  >*please provide a description</small
                >
                <div class="d-flex align-center" style="gap: 0.5rem">
                  <v-btn
                    v-if="user.downloadCredits == 0"
                    :to="{ name: 'Subscription' }"
                    :loading="downloading"
                    color="primary"
                    class="mr-2"
                    >Buy More Credits</v-btn
                  >

                  <v-btn
                    @click="generateMealplanLink()"
                    :loading="generatingLink"
                    :disabled="!plan.mealplanDescription"
                    color="primary"
                    class="mr-2"
                    v-else
                    >{{
                      plan?.mealplanLink ? "Update Link" : "Generate Meal Plan Link"
                    }}</v-btn
                  >

                  <v-btn
                    @click="confirmGenerateMealplan = false"
                    :disabled="generatingLink"
                    text
                    >Cancel</v-btn
                  >
                  <v-switch
                    @change="
                      $store.dispatch('mealplans/updateMealField', {
                        value: plan.hideInstructions,
                        field: 'hideInstructions',
                        meal: plan,
                      })
                    "
                    label="Hide Instruction page"
                    v-model="plan.hideInstructions"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- DOWNLOADING DIALOG -->
        <v-dialog v-model="zipDialog" max-width="380" persistent>
          <v-card>
            <v-card-text class="pt-5">
              <div>
                <div class="subtitle-1">Please wait...</div>
                <v-progress-linear :value="downloadProgress" color="primary" height="25">
                  <template v-slot:default>
                    <strong>{{ downloadProgress }}%</strong>
                  </template>
                </v-progress-linear>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- MOVE DIALOG -->
        <v-dialog v-model="moveDialog" max-width="380">
          <close-button @click="moveDialog = false" overflow />
          <v-card>
            <v-card-text class="pt-5">
              <div>
                <p>Move to:</p>
                <v-autocomplete
                  v-model="selectedMoveFolder"
                  :items="folders"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  label="Select folder"
                >
                </v-autocomplete>
              </div>
            </v-card-text>
            <v-card-text>
              <v-btn
                :loading="status.moving"
                @click="moveMealplan()"
                color="primary"
                depressed
                :disabled="!selectedMoveFolder"
              >
                Move
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- DUPLICATE DIALOG -->
        <v-dialog v-model="duplicateDialog" max-width="380">
          <close-button @click="duplicateDialog = false" overflow />
          <v-card>
            <v-card-text class="pt-5">
              <div>
                <p>Duplicate to:</p>
                <v-autocomplete
                  v-model="selectedDuplicateFolder"
                  :items="duplicateFolders"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  label="Select folder"
                >
                </v-autocomplete>
              </div>
              <div>
                <v-text-field
                  v-model="duplicateName"
                  label="Mealplan Name"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-card-text>
            <v-card-text>
              <v-btn
                :loading="status.duplicating"
                @click="duplicateMealplan()"
                color="primary"
                depressed
                :disabled="!selectedDuplicateFolder || !duplicateName"
              >
                Duplicate
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <CalculatorDialog
        ref="calculatorDialog"
        :plan="plan"
        @getId="getGoal"
        @addedNewMeal="addedNewMeal"
        @closeCalculator="closeCalculator"
        :editNewMealplan="editNewMealplan"
      />
      <v-dialog v-model="showEbookUpdate" max-width="290">
        <v-card>
          <v-card-title class="headline"> Notice </v-card-title>

          <v-card-text>
            <p>Please allow 1-2 minutes for any eBook links to be updated</p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showEbookUpdate = false"> Okay </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- REQUEST DIALOG -->
      <v-dialog v-model="requestDialog" max-width="650">
        <close-button @click="requestDialog = false" overflow />
        <v-card>
          <v-card-title primary-title> Please confirm </v-card-title>
          <v-card-text>
            <div class="subtitle-1">
              You are about to request a dietitian review of this meal plan.
            </div>
            <div class="caption mb-3" v-if="plan?.requestStatus != 'edited'">
              You have {{ user?.requestTokens || 0 }} tokens left
            </div>
            <div class="caption mb-3 error--text" v-if="plan?.hasEdited">
              It seems that you have edited and resubmitted this meal plan already, a
              credit will be use when you submit this meal plan again ({{
                user?.requestToken || 0
              }}
              token available)
            </div>
            <div
              class="caption mb-3"
              v-if="!plan?.hasEdited && plan?.requestStatus == 'edited'"
            >
              You have a free token to resubmit this meal plan
            </div>

            <div class="subtitle-2">Additional Information:</div>
            <vue-editor
              v-if="plan?.requestStatus != 'edited'"
              v-model="additionalInfo"
              placeholder="Please add any additional information to help us review the meal plan. Could you include any medical history or medications relevant to the client? Our review is based solely on the information you provide."
              class="mb-3"
            />

            <vue-editor
              v-else
              v-model="plan.additionalInfo"
              placeholder="Please add any additional information to help us review the meal plan. Could you include any medical history or medications relevant to the client? Our review is based solely on the information you provide."
              class="mb-3"
            />

            <v-btn
              @click="requestApproval(false, true)"
              :loading="requesting"
              color="primary"
              class="mr-2"
              :disabled="!additionalInfo"
              v-if="plan?.requestStatus == 'rejected'"
              medium
              >Confirm</v-btn
            >
            <v-btn
              @click="
                !plan?.hasEdited
                  ? requestApprovalAgain()
                  : hasRequestTokens
                  ? requestApprovalAgain()
                  : (reqTokenWarning = true)
              "
              :loading="requesting"
              color="primary"
              class="mr-2"
              :disabled="!plan.additionalInfo"
              v-else-if="plan?.requestStatus == 'edited'"
              medium
              >Confirm</v-btn
            >
            <v-btn
              v-else
              @click="hasRequestTokens ? requestApproval() : (reqTokenWarning = true)"
              :loading="requesting"
              color="primary"
              class="mr-2"
              :disabled="!additionalInfo"
              medium
              >Confirm</v-btn
            >

            <v-btn @click="requestDialog = false" text>Cancel</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDownload" max-width="500">
        <close-button @click="confirmDownload = false" overflow />
        <v-card>
          <v-card-title primary-title> Please confirm </v-card-title>
          <v-card-text>
            <p v-if="parseInt(user.downloadCredits) == -1">
              You have unlimited download credits.
            </p>
            <p v-else-if="user.downloadCredits <= 0">
              You don't have any download credits left.
            </p>
            <p v-else>
              Download credits: {{ user.downloadCredits }} <br />
              {{
                user.downloadCredits == 1
                  ? "This is your last download credit."
                  : "This will use one of your download credits."
              }}
            </p>

            <p v-for="(status, i) in statuses" :key="i">
              {{ status }}
            </p>

            <v-btn
              v-if="user.downloadCredits == 0"
              :to="{ name: 'Subscription' }"
              :loading="downloading"
              color="primary"
              class="mr-2"
              >Buy More Credits</v-btn
            >

            <v-btn
              @click="downloadConfirmed()"
              :loading="downloading"
              color="primary"
              class="mr-2"
              v-else
              >Confirm Download</v-btn
            >

            <v-btn @click="confirmDownload = false" :disabled="downloading" text
              >Cancel</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog max-width="380" v-model="reqWarning">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold">CAUTION</span>
          </v-card-title>
          <v-card-text>
            <div>
              ANY EDITS TO THIS MEAL PLAN WILL REMOVE THE DIETITIAN APPROVED STAMPING AND
              WOULD REQUIRE A REVIEW TO HAVE IT APPROVED ONCE AGAIN
            </div>
            <div class="mt-5">
              <v-btn
                @click="showCalculator(true)"
                color="warning white--text"
                small
                class="mr-2"
                depressed
                >Continue editing</v-btn
              >
              <v-btn
                @click="reqWarning = false"
                color="black--text"
                class="mr-2"
                small
                depressed
                >close</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog max-width="380" v-model="reqWarning1">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold">CAUTION</span>
          </v-card-title>
          <v-card-text>
            <div>
              ANY EDITS TO THIS MEAL PLAN WILL REMOVE THE DIETITIAN APPROVED STAMPING AND
              WOULD REQUIRE A REVIEW TO HAVE IT APPROVED ONCE AGAIN
            </div>
            <div class="mt-5">
              <v-btn
                @click="dropped(droppedData)"
                color="warning white--text"
                small
                class="mr-2"
                depressed
                >Continue</v-btn
              >
              <v-btn
                @click="reqWarning1 = false"
                color="black--text"
                class="mr-2"
                small
                depressed
                >close</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog max-width="380" v-model="reqWarning2">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold">CAUTION</span>
          </v-card-title>
          <v-card-text>
            <div>
              ANY EDITS TO THIS MEAL PLAN WILL REMOVE THE DIETITIAN APPROVED STAMPING AND
              WOULD REQUIRE A REVIEW TO HAVE IT APPROVED ONCE AGAIN
            </div>
            <div class="mt-5">
              <v-btn
                @click="reqWarning2 = false"
                color="warning white--text"
                small
                class="mr-2"
                depressed
                >Got it</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog max-width="380" v-model="reqTokenWarning" persistent>
        <close-button @click="reqTokenWarning = false" overflow />
        <v-card>
          <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold">NOTICE</span>
          </v-card-title>
          <v-card-text>
            <div class="mb-3">You don't have enough request tokens to proceed.</div>
            <div class="mb-1">You can purchase:</div>
            <div>
              <v-btn
                v-for="(sp, i) in stampPrices"
                :key="sp.id"
                @click="buyToken(sp)"
                :color="i % 2 == 0 ? 'info' : 'primary'"
                :loading="buyingToken"
                small
                class="mr-2"
                depressed
                >{{ sp.name }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog max-width="400" v-model="alternateDialog">
        <v-card>
          <v-card-title>
            <span class="font-weight-bold"
              >Set an Alternate name for "{{ wordToAlter }}"</span
            >
          </v-card-title>
          <v-card-text>
            <div>
              <v-text-field
                v-model="alternateWords[wordToAlter]"
                placeholder=""
                hide-details
                clearable
                outlined
                dense
                background-color="white"
              ></v-text-field>
            </div>
            <div class="mt-5">
              <v-btn
                @click="confirmAlternate"
                :loading="loadingAlter"
                color="primary white--text"
                small
                class="mr-2"
                depressed
                >Confirm</v-btn
              >

              <v-btn
                @click="alternateDialog = false"
                color="grey white--text"
                small
                class="mr-2"
                depressed
                >Close</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="viewOnly">
      <view-mealplan
        :user="user"
        :mainMeals="mainMeals"
        :meals="meals"
        :goal="goal"
        :rcps="recipes"
        :myRecipes="myRecipes"
        :plan="plan"
        :name="plan.name"
        @downloading="downloadingPDF"
        @beforeDownload="beforeDownload"
        @downloadFinish="downloadPDFFinish"
      />
    </div>
  </div>
</template>

<script src="https://player.vimeo.com/api/player.js"></script>

<script>
// PLUGINS
import JSZip from "jszip";
import moment from "moment";
import pluralize from "pluralize";
import { saveAs } from "file-saver";
import { mapState, mapGetters, mapActions } from "vuex";
import { sumBy, intersection, uniqBy, truncate, capitalize } from "lodash";

import Vue from "vue";

// COMPONENTS
import FindMeal from "./FindMealDialog";
import TableMeal from "../components/TableMeal";
import CalculatorDialog from "./CalculatorDialog";
import RecipeCard from "../../recipe/components/RecipeCard";
import ShoppingListDialog from "@/components/ShoppingListDialog";
import DownloadMealplan from "./DownloadMealplan";
import ViewMealplan from "./ViewMealplan";
import WarningDialog from "./WarningDialog.vue";
import { VueEditor } from "vue2-editor";
import Tags from "../components/Tags.vue";

// MIXINS
import DownloadPdf from "../../../mixins/download-pdf";
import downloadRecipe from "../../../mixins/download-recipe-pdf";

import db from "@/firebase/init";
import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.VUE_APP_ALGOLIA_APP_ID,
  "b01a34dc50c42c2e6fe90f9ec6d376ad"
);
const index = client.initIndex("foods");

let zip;

export default {
  name: "PlansTable",

  props: {
    selectedPlan: Object,
    plans: Array,
    reqPlans: Array,
    allPlans: Array,
    roleType: String,
    viewOnly: Boolean,
    currentFolder: Object,
    isOnMealPlanRoute: Boolean,
  },

  /*------------------------------------------------------------------------------
   * MIXINS
   *----------------------------------------------------------------------------*/
  mixins: [DownloadPdf, downloadRecipe],

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      index: 0,
      pluralize,
      cell: null,
      cell_1: null,
      values: [],
      image: null,
      serves: null,
      recipe: null,
      serves: null,
      nutrients: [],
      overlay: true,
      fetching: true,
      toRemove: null,
      showCard: false,
      ingredients: [],
      mealPrep: false,
      unlocking: false,
      locking: false,
      zipDialog: false,
      searchPlan: null,
      showDelete: false,
      itemDragged: null,
      itemDragged: null,
      downloading: false,
      deleteMessage: null,
      recentlyDeleted: [],
      showFindDialog: false,
      confirmDownload: false,
      confirmGenerate: false,
      generatingLink: false,
      generatePdf: false,
      statuses: [],
      nutritionInfo: {
        weight: 0,
        energy: 0,
        protein: 0,
        carbohydrate: 0,
        sugar: 0,
        fibre: 0,
        sodium: 0,
        fat: 0,
      },
      routeUrl: "",
      confirmOpenTab: false,
      moveDialog: false,
      selectedMoveFolder: "",
      editNewMealplan: false,
      selectedDuplicateFolder: "",
      duplicateName: null,
      duplicateDialog: false,
      confirmGenerateMealplan: false,
      showEbookUpdate: false,
      requestDialog: false,
      requesting: false,
      additionalInfo: "",
      reqWarning: false,
      reqWarning1: false,
      reqWarning2: false,
      droppedData: null,
      reqTokenWarning: false,
      buyingToken: false,
      truncate,
      alternateDialog: false,
      wordToAlter: "",
      planSlide: null,
      alternateWords: {},
      loadingAlter: false,
      refreshingEbook: false,
      converting: false,
      recipePlanTags: {
        hideEnergy: false,
        hideMealTags: false,
        hideNutriTags: false,
        hideCustomization: false,
      },
      toggleCustomLoading: false,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Tags,
    FindMeal,
    TableMeal,
    VueEditor,
    RecipeCard,
    CalculatorDialog,
    ShoppingListDialog,
    DownloadMealplan,
    ViewMealplan,
    WarningDialog,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      goal: (state) => state.goal.goal,
      mls: (state) => state.mealplans.meals,
      status: (state) => state.mealplans.status,
      recipes: (state) => state.recipes.recipes,
      myRecipes: (state) => state.recipes.myRecipes,
      allFolders: (state) => state.mealplans.folders,
      totalValues: (state) => state.recipes.totalValues,
      stampPrices: (state) => state.pricing.stampPrices,
      recipeIngredientIds: (state) => state.recipes.recipeIngredientIds,
    }),

    plan: function () {
      if(!this.isOnMealPlanRoute) {
        if(this.selectedPlan?.linkDetails?.user?.promoData) {
          const { hideEnergy, hideMealTags, hideNutriTags, hideCustomization } = this.selectedPlan.linkDetails.user.promoData

          this.recipePlanTags = {
            hideEnergy: hideEnergy || false,
            hideMealTags: hideMealTags || false,
            hideNutriTags: hideNutriTags || false,
            hideCustomization: hideCustomization || false,
          }
        }
      }

      return this.selectedPlan;
    },

    meals: function () {
      let meals = this.mls;

      if (this.goal) {
        let mainMeals = intersection(
          ["breakfast", "lunch", "dinner", "desert"],
          this.goal.mainMeals
        );

        if (this.goal.snacks) {
          for (let i = 1; i <= this.goal.snacks; i++) {
            mainMeals.push(`snack${i}`);
          }
        }

        meals = meals.filter((meal) => {
          return mainMeals.includes(meal.meal);
        });
      }

      return meals;
    },

    ...mapGetters("subscriptions", ["active"]),
    ...mapGetters("user", ["hasRequestTokens"]),

    subscription: function () {
      let active = this.$store.getters["subscriptions/subscribed"];
      return active;
    },

    activePlan: function () {
      return this.active;
    },

    planStartDate() {
      let date = this.activePlan.current_period_start.seconds;
      date = new Date(date * 1000);
      return date;
    },

    totals: function () {
      let totals = [];
      return totals;
    },

    mainMeals: function () {
      return intersection(
        ["breakfast", "lunch", "dinner", "desert"],
        this.goal.mainMeals
      );
    },

    downloadProgress: function () {
      var meals = this.meals.filter(
        (m) =>
          m.recipe !== "no meal" &&
          Object.keys(this.$store.getters["recipes/recipe"](m.recipe))?.length
      )?.length;
      var progress = this.index / meals;
      return Math.round(progress * 100);
    },

    folders: function () {
      return this.allFolders?.length
        ? this.allFolders
            .filter((f) => f?.client != this.currentFolder?.client)
            .map((f) => ({ text: f?.client, value: f.id }))
        : [];
    },
    duplicateFolders: function () {
      return this.allFolders?.length
        ? this.allFolders.map((f) => ({
            text:
              f?.client == this.currentFolder?.client
                ? `${f?.client} (this folder)`
                : f.client,
            value: f.id,
          }))
        : [];
    },

    recipeLink: function () {
      const link = `${window.origin}/ebook/${this.removeSpace(
        `${this.user.firstName}${this.user.lastName}`
      )}/${this.removeSpace(this.plan.name.replace(/#/gi, ""))}?i=${this.plan.id}`;

      return link;
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    "plan.id": {
      handler(id) {
        if (id) {
          this.$store.commit("recipes/resetState");
          this.fetching = true;
          this.nutrients = [];

          if (this.currentFolder?.client) this.getMeals();

          if (
            this.user &&
            ["dietitian", "personal_trainer", "nutritionist", "naturopath"].includes(
              this.user.role
            )
          )
            this.getGoal(id);
        }
      },
      immediate: true,
    },

    // 'plan.hideEnergy': {
    //   handler(energy) {
    //     let enrgy = { 'hide-energy': energy }
    //     let query = this.$route.query
    //     this.$router.push({query: {...query, ...enrgy}})
    //   },
    //   immediate: true
    // },

    "plan.mainMeals": function (value) {
      this.$store.dispatch("mealplans/updateMealField", {
        meal: this.plan,
        field: "mainMeals",
        value,
      });
    },

    "plan.snacks": function (value) {
      this.$store.dispatch("mealplans/updateMealField", {
        meal: this.plan,
        field: "snacks",
        value,
      });
    },
    "nutritionInfo.weight": function (value) {
      if (this.plan?.weight !== value) {
        this.addNutritionInfo({ name: "weight", value, plan: this.plan });
      }
    },
    "nutritionInfo.energy": function (value) {
      if (this.plan?.energy !== value) {
        this.addNutritionInfo({ name: "energy", value, plan: this.plan });
      }
    },
    "nutritionInfo.protein": function (value) {
      if (this.plan?.protein !== value) {
        this.addNutritionInfo({ name: "protein", value, plan: this.plan });
      }
    },
    "nutritionInfo.fat": function (value) {
      if (this.plan?.fat !== value) {
        this.addNutritionInfo({ name: "fat", value, plan: this.plan });
      }
    },
    "nutritionInfo.carbohydrate": function (value) {
      if (this.plan?.carbohydrate !== value) {
        this.addNutritionInfo({ name: "carbohydrate", value, plan: this.plan });
      }
    },
    "nutritionInfo.sugar": function (value) {
      if (this.plan?.sugar !== value) {
        this.addNutritionInfo({ name: "sugar", value, plan: this.plan });
      }
    },
    "nutritionInfo.fibre": function (value) {
      if (this.plan?.fibre !== value) {
        this.addNutritionInfo({ name: "fibre", value, plan: this.plan });
      }
    },
    "nutritionInfo.sodium": function (value) {
      if (this.plan?.sodium !== value) {
        this.addNutritionInfo({ name: "sodium", value, plan: this.plan });
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("mealplans", [
      "addGeneratedMealplanLink",
      "moveToAnotherFolder",
      "updateRecipeLink",
      "addNutritionInfo",
      "insertBlankMeal",
      "updateMealField",
      "updateMealField",
      "addGeneratedLink",
      "addPlanToFolder",
      "purchaseToken",
      "duplicateMeal",
      "autoGenerate",
      "create",
    ]),

    ...mapActions("goal", ["update"]),

    updateValues(meal, i, e) {
      var val = this.values.find((v) => v.meal == meal && v.day == i);
      var newData = {
        meal,
        day: i,
        values: e,
      };

      if (!val) {
        this.values.push(newData);
      } else {
        this.values[this.values.indexOf(val)] = newData;
      }
    },

    setDate(add) {
      let startDate = moment(this.planStartDate);
      let diff = moment().diff(startDate, "day");

      if (diff > 7) {
        let weeks = Math.trunc(diff / 7);
        startDate = startDate.add(weeks, "weeks");
      }

      return startDate.add(add, "days").valueOf();
    },

    getDay(day) {
      switch (day) {
        case 1:
          day = "Monday";
          break;
        case 2:
          day = "Tuesday";
          break;
        case 3:
          day = "Wednesday";
          break;
        case 4:
          day = "Thursday";
          break;
        case 5:
          day = "Friday";
          break;
        case 6:
          day = "Saturday";
          break;
        case 7:
          day = "Sunday";
          break;
      }

      return day;
    },

    checkCurrent(day) {
      day++;
      if (day == 7) day = 0;
      let date = new Date();
      let d = date.getDay();
      return d == day;
    },

    addMealPlan(e, meal, day) {
      let hasMeal = this.meals.find((m) => m.meal == meal && m.day == day);

      if (hasMeal) {
        this.cell = hasMeal;
        this.cell.plan = this.plan;
      } else {
        this.cell = {
          meal,
          day,
          plan: this.plan,
        };
      }

      if (e == "find a meal") {
        this.$refs.findMealDialog.showDialog();

        if (this.plan?.requestStatus == "approved") {
          setTimeout(() => {
            this.reqWarning2 = true;
          }, 1000);
        }
        // this.$refs[`${meal}-${day}`].value = null
      } else {
        this.insertBlankMeal(this.cell);
      }
    },

    closeAddDialog() {
      this.showFindDialog = false;

      if (this.hasMeal(this.cell.meal, this.cell.day) == "no meal") {
        this.$refs[`${this.cell.meal}-${this.cell.day}`][0].setValue("no meal");
      } else {
        this.$refs[`${this.cell.meal}-${this.cell.day}`][0].reset();
      }
    },

    getMeals() {
      Promise.resolve(this.$store.dispatch("mealplans/getMeals", this.plan)).then(() => {
        this.fetching = false;
      });
    },

    hasMeal(meal, day) {
      let hasMeal = null;
      let noMeal = null;

      hasMeal = this.meals.find((m) => m.meal == meal && m.day == day);

      if (hasMeal) {
        noMeal = hasMeal.recipe == "no meal" ? "no meal" : null;
      }

      return noMeal || hasMeal || false;
    },

    recentlyDeletedMeal(meal, day) {
      let deleted = null;

      deleted = this.recentlyDeleted.find((m) => m.meal == meal && m.day == day);

      return !!deleted;
    },

    showCardDialog(data) {
      console.log({ data })
      this.recipe = data.recipe;
      this.serves = data.serves;
      this.mealPrep = data.mealPrep;
      this.showCard = true;
      this.cell_1 = {
        day: data.day,
        meal: data.mealTime,
        plan: this.plan,
      };
      this.meal = data.meal;
    },

    showOriginalRec(data) {
      this.showCard = false;

      let meal = this.meals.find((m) => m.id === data.meal);

      setTimeout(() => {
        this.recipe = data.recipe;
        this.serves = meal.serves;
        this.mealPrep = data.mealPrep;
        this.showCard = true;
        this.cell = {
          day: data.day,
          meal: data.mealTime,
          plan: this.plan,
        };

        this.meal = meal;
      }, 500);
    },

    // GET TOTAL kJ
    getTotal(day, html = true) {
      let total = 0;

      let nutrients = this.nutrients.filter((nutrient) => {
        return nutrient.day == day;
      });

      total = sumBy(nutrients, (n) => {
        return n.nutrient.nutrientsData.energy / n.serves;
      });

      let color =
        total <= this.$store.getters["goal/dailyEnergyRequirement"] ? "primary" : "red";
      return html
        ? `<span class="${color}--text">${this.$options.filters.numberFormat(
            Math.round(total)
          )} kJ</span>`
        : this.$options.filters.numberFormat(Math.round(total));
    },

    // GET NUTRIENTS
    getTotalNutrientValue(day, type) {
      var total = 0;
      var meals = this.meals.filter(
        (m) => (m.day == day || day >= 8) && m.recipe !== "no meal"
      );
      var recipeIds = meals.map((m) => m.recipe);
      var recipes = [];

      if (this.recipes?.length) {
        recipeIds.forEach((id) => {
          let allRec = [...this.recipes, ...this.myRecipes];
          let rec = allRec.find((r) => r.id == id);
          if (rec) recipes.push(rec);
        });
      }

      if (recipes?.length) {
        total = sumBy(recipes, (r) => r.servingValues[type]);
      }

      var countMeals = uniqBy(meals, (meal) => meal.day);

      // DISABLE THE LOADER
      // if (recipes.length === meals.length && day === 7) {
      //   this.overlay = false

      //   const nutrients = ['energy', 'weight', 'protein', 'fat', 'carbohydrate', 'sugar', 'fibre', 'sodium']

      //   nutrients.forEach(nutrient => this.getTheDayEightNutrientValue(8, nutrient))

      // }

      if (recipes?.length === meals?.length && day === 8) {
        if (!this.nutritionInfo[type]) {
          this.nutritionInfo[type] = Math.round(total / countMeals?.length);
        }
      }

      return Math.round(day >= 8 ? total / countMeals?.length : total);
    },

    getTheDayEightNutrientValue(day, type) {
      var total = 0;
      var meals = this.meals.filter(
        (m) => (m.day == day || day >= 8) && m.recipe !== "no meal"
      );
      var recipeIds = meals.map((m) => m.recipe);
      var recipes = [];

      if (this.recipes?.length) {
        recipeIds.forEach((id) => {
          let allRec = [...this.recipes, ...this.myRecipes];
          let rec = allRec.find((r) => r.id == id);
          if (rec) recipes.push(rec);
        });
      }

      if (recipes?.length) {
        total = sumBy(recipes, (r) => r.servingValues[type]);
      }

      var countMeals = uniqBy(meals, (meal) => meal.day);

      if (!this.nutritionInfo[type]) {
        this.nutritionInfo[type] = Math.round(total / countMeals?.length);
      }
    },

    removeMeal(meal, day, data) {
      // this.deleteMessage = `Remove recipe "${ data.recipe.name }"?`
      // this.showDelete = true
      this.toRemove = { meal, day, data };
      let deleted = this.recentlyDeleted.find((m) => m.meal === meal && m.day === day);

      if (deleted) {
        this.$set(this.recentlyDeleted, this.recentlyDeleted.indexOf(deleted), {
          meal,
          day,
          data,
        });
      } else {
        this.recentlyDeleted.push({ meal, day, data });
      }
      this.deleteConfirmed();
    },

    deleteConfirmed() {
      Promise.all([
        this.$store.dispatch("mealplans/delete", this.toRemove.data.meal),
      ]).then(() => {
        this.showDelete = false;
        this.$store.commit("recipes/removeValue", {
          meal: this.toRemove.meal,
          day: this.toRemove.day,
        });
      });
    },

    getRecipeName(meal, day) {
      let name = "none";

      let hasMeal = this.meals.find((m) => {
        return m.meal == meal && m.day == day;
      });

      if (hasMeal) {
        let allRec = [...this.recipes, ...this.myRecipes];
        let recipe = allRec.find((r) => r.id == hasMeal.recipe);
        if (recipe) name = recipe.name;
      }

      return name;
    },

    getRecipeID(name) {
      let allRect = [...this.recipes, ...this.myRecipes];
      let recipe = this.recipes.find((r) => r.name == name);
      return recipe ? recipe.id : {};
    },

    getMealID(time, day) {
      if (time.toLowerCase().split(" ")[0] == "snack") time = time.split(" ").join("");

      let meal = this.meals.find(
        (meal) => meal.day === day && meal.meal === time.toLowerCase()
      );

      return meal.id;
    },

    getIngredient: function (id) {
      let name = "";
      let ingredient = this.ingredients.find((ing) => ing.id == id);

      if (ingredient) {
        name = ingredient.Name;
      }

      return name;
    },

    updateName(newValue, plan) {
      if (newValue !== plan.name) {
        if (newValue?.length > 25) {
          this.$store.dispatch(
            "showError",
            "Mealplan name should only have 25 characters or less"
          );
        }
        this.$store.dispatch("pricing/updatePlanField", {
          plan,
          field: "name",
          value: truncate(newValue, { length: 25, omission: "" }),
        });
      }
    },

    // downloadRecipes() {
    //   var recipes = this.meals.filter(m => m.recipe !== 'no meal')
    //   recipes = recipes.map(r => r.recipe)
    //   recipes = uniq(recipes)
    //   window.open(`${window.origin}${this.$router.resolve({ name: 'DownloadRecipe', query: { ids: recipes.join("|"), 'hide-energy': this.plan.hideEnergy }}).href}`, '_blank')
    // },

    startDownloadRecipes(index) {
      if (this.user.role != "member") {
        if (!this.user.customLogo || !this.user.brandColor) this.openWarningDialog();
        else {
          this.index = index;
          this.downloadRecipes(index);
          zip = new JSZip();
        }
      } else {
        this.index = index;
        this.downloadRecipes(index);
        zip = new JSZip();
      }
    },

    downloadRecipes(index) {
      console.log("start");
      var meals = this.meals.filter((m) => m.recipe !== "no meal");
      var meal = meals[index];
      this.zipDialog = true;

      this.recipe = this.$store.getters["recipes/recipe"](meal.recipe);

      this.serves = meal.serves;

      if (!Object.keys(this.recipe)?.length) {
        console.log("call");
        this.index = index + 1;
        this.downloadRecipes(this.index);
      } else {
        console.log("download");
        this.image = this.recipe.mediumPhotoUrl || this.recipe.OriginalPhotoUrl;

        Promise.resolve(this.downloadPDF(true))
          .then((doc) => {
            console.log(doc);
            zip.file(`${this.recipe.name}.pdf`, doc.output("blob"));
            console.log(this.index + 1 == meals?.length);

            if (this.index + 1 == meals?.length) {
              zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, "recipes.zip");
                this.zipDialog = false;
              });
            } else {
              console.log("call again");
              this.index = index + 1;
              this.downloadRecipes(this.index);
            }
          })
          .catch(() => {
            this.downloadRecipes(this.index);
          });
      }
    },

    getGoal() {
      this.$store.dispatch("goal/getGoal", this.plan.id);
    },

    downloadConfirmed() {
      this.downloading = true;
      this.generatePdf = true;
      this.statuses.push("Starting Download..");

      // TO BE CLEAN UP

      // this.addGeneratedLink({ plan: this.plan, details: {
      //   user: {
      //     customLogoUrl: this.user.customLogo ? this.user.customLogoUrl : '',
      //     brandColor: this.user.brandColor,
      //     role: this.user.role,
      //     firstName: this.user.firstName,
      //     lastName: this.user.lastName,
      //     energyUnit: this.user.energyUnit,
      //     id: this.user.id,
      //     hideEnergy: this.plan.hideEnergy ? this.plan.hideEnergy : false,
      //     instagram: this.user?.instagram ? this.generateRedirectLink(this.user.instagram) : '',
      //     facebook: this.user?.facebook ? this.generateRedirectLink(this.user.facebook) : '',
      //     website: this.user?.website ? this.generateRedirectLink(this.user.website) : '',
      //   },
      //   recipes: this.recipes,
      //   mainMeals: this.mainMeals,
      //   meals: this.meals,
      //   goal: this.goal
      // }})
      // .finally(() => {
      //   const routeData = this.$router.resolve({ name: 'DownloadMealplan', query: { i: this.plan.id } })

      // })

      // Promise.resolve(this.downloadMealPlanPdf())
      // .then(() => {
      //   this.downloading = false
      //   this.confirmDownload = false

      //   if (parseInt(this.user.downloadCredits) >= 1) {
      //     this.$store.dispatch('user/updateUserField', {
      //       value: this.user.downloadCredits - 1,
      //       field: 'downloadCredits',
      //       silent: true,
      //     })
      //   }

      //   this.$store.dispatch('mealplans/countDownload', {
      //     meals: this.meals.filter(m => m.recipe !== 'no meal'),
      //     goal: this.goal,
      //     plan: this.plan,
      //   })
      // })
    },

    downloadingPDF() {
      this.statuses.push("Downloading PDF..");
    },
    beforeDownload() {
      this.statuses.push("Download Success..");
    },
    downloadPDFFinish() {
      this.generatePdf = false;
      this.downloading = false;
      this.confirmDownload = false;

      if (parseInt(this.user.downloadCredits) >= 1) {
        this.$store.dispatch("user/updateUserField", {
          value: this.user.downloadCredits - 1,
          field: "downloadCredits",
          silent: true,
          hasDownloadedAMealPlan: true,
        });
      }

      if (!this.plan?.hasDownloaded)
        this.$store.dispatch("mealplans/addHasDownloaded", { plan: this.plan });

      this.$store.dispatch("mealplans/countDownload", {
        meals: this.meals.filter((m) => m.recipe !== "no meal"),
        goal: this.goal,
        plan: this.plan,
      });
    },

    openTab() {
      window.open(this.routeUrl, "_blank");
      this.confirmOpenTab = false;
    },

    generateLink() {
      if (!this.user.customLogo || !this.user.brandColor) return this.openWarningDialog();

      this.generatingLink = true;
      const promoData = this.plan?.linkDetails?.user?.promoData || {}



      const details = {
        user: {
          id: this.user.id,
          role: this.user.role,
          lastName: this.user.lastName,
          firstName: this.user.firstName,
          brandColor: this.user.brandColor,
          energyUnit: this.user.energyUnit,
          isStudent: this.user?.isStudent ? true : false,
          hideEnergy: this.plan.hideEnergy ? this.plan.hideEnergy : "",
          customLogoUrl: this.user.customLogo ? this.user.customLogoUrl : "",
          promoData: this.recipePlanTags ? {...promoData, ...this.recipePlanTags} : promoData,
        },
        recipes: [...this.myRecipes],
        mainMeals: this.mainMeals,
        meals: this.meals,
        goal: this.goal,
      };

      this.addGeneratedLink({
        plan: this.plan,
        details,
        silent: false,
      }).finally(() => {
        this.generatingLink = false;

        if (parseInt(this.user.downloadCredits) >= 1) {
          this.$store.dispatch("user/updateUserField", {
            value: this.user.downloadCredits - 1,
            field: "downloadCredits",
            silent: true,
          });
        }

        this.$store.dispatch("mealplans/countDownload", {
          meals: this.meals.filter((m) => m.recipe !== "no meal"),
          goal: this.goal,
          plan: this.plan,
        });
      });
    },

    generateMealplanLink() {
      if (!this.user.customLogo || !this.user.brandColor) return this.openWarningDialog();

      this.generatingLink = true;

      let recipes = this.meals.map((m) => {
        let recipe = this.recipes.find((r) => r.id == m.recipe);

        return recipe ? recipe : {};
      });

      const details = {
        user: {
          id: this.user.id,
          role: this.user.role,
          lastName: this.user.lastName,
          firstName: this.user.firstName,
          brandColor: this.user.brandColor,
          energyUnit: this.user.energyUnit,
          isStudent: this.user?.isStudent ? true : false,
          hideEnergy: this.plan.hideEnergy ? this.plan.hideEnergy : "",
          customLogoUrl: this.user.customLogo ? this.user.customLogoUrl : "",
        },
        recipes: [...recipes, ...this.myRecipes],
        mainMeals: this.mainMeals,
        meals: this.meals,
        goal: this.goal,
      };

      this.addGeneratedMealplanLink({
        plan: this.plan,
        details,
        silent: false,
      }).finally(() => {
        this.generatingLink = false;

        if (parseInt(this.user.downloadCredits) >= 1) {
          this.$store.dispatch("user/updateUserField", {
            value: this.user.downloadCredits - 1,
            field: "downloadCredits",
            silent: true,
          });
        }

        this.$store.dispatch("mealplans/countDownload", {
          meals: this.meals.filter((m) => m.recipe !== "no meal"),
          goal: this.goal,
          plan: this.plan,
        });
      });
    },

    copyLink(link) {
      navigator.clipboard.writeText(this.removeSpace(link));
      this.$store.dispatch("showSuccess", "URL copied to clipboard");
    },

    copyRecipeLink() {
      const link = `https://mealguide.pro/promoebookpage/${this.removeSpace(
        `${this.user.firstName}${this.user.lastName}`
      )}/${this.removeSpace(this.plan.name.replace(/#/gi, ""))}?i=${this.plan.id}`;
      // const link = `${
      //   window.origin
      // }/promoebookpage/${this.removeSpace(
      //   `${this.user.firstName}${this.user.lastName}`
      // )}/${this.removeSpace(this.plan.name.replace(/#/gi, ""))}?i=${
      //   this.plan.id
      // }`;

      navigator.clipboard.writeText(this.removeSpace(link));
      this.$store.dispatch("showSuccess", "URL copied to clipboard");
    },

    lockAll() {
      if (this.meals?.length) {
        let promises = [];
        this.locking = true;

        this.meals.forEach((meal) => {
          promises.push(
            this.$store.dispatch("mealplans/updateMealField", {
              meal,
              value: true,
              silent: true,
              field: "locked",
            })
          );
        });

        Promise.all(promises).then(() => {
          this.$store.dispatch("showSuccess", "All meals locked!");
          this.locking = false;
        });
      }
    },

    unlockAll() {
      if (this.meals?.length) {
        let promises = [];
        this.unlocking = true;

        this.meals.forEach((meal) => {
          promises.push(
            this.$store.dispatch("mealplans/updateMealField", {
              meal,
              value: false,
              silent: true,
              field: "locked",
            })
          );
        });

        Promise.all(promises).then(() => {
          this.$store.dispatch("showSuccess", "All meals unlocked");
          this.unlocking = false;
        });
      }
    },

    lockByMealTime(mealTime) {
      if (this.meals?.length) {
        let promises = [];
        this.locking = true;

        this.meals.forEach((meal) => {
          if (meal.meal === mealTime) {
            promises.push(
              this.$store.dispatch("mealplans/updateMealField", {
                meal,
                value: true,
                silent: true,
                field: "locked",
              })
            );
          }
        });

        Promise.all(promises).then(() => {
          this.$store.dispatch(
            "showSuccess",
            `All ${capitalize(mealTime)} meals locked!`
          );

          this.locking = false;
        });
      }
    },

    unlockByMealTime(mealTime) {
      if (this.meals?.length) {
        let promises = [];
        this.locking = true;

        this.meals.forEach((meal) => {
          if (meal.meal === mealTime) {
            promises.push(
              this.$store.dispatch("mealplans/updateMealField", {
                meal,
                value: false,
                silent: true,
                field: "locked",
              })
            );
          }
        });

        Promise.all(promises).then(() => {
          this.$store.dispatch(
            "showSuccess",
            `All ${capitalize(mealTime)} meals unlocked`
          );
          this.locking = false;
        });
      }
    },

    lockByDay(day) {
      if (this.meals?.length) {
        let promises = [];
        this.locking = true;

        this.meals.forEach((meal) => {
          if (meal.day === day) {
            promises.push(
              this.$store.dispatch("mealplans/updateMealField", {
                meal,
                value: true,
                silent: true,
                field: "locked",
              })
            );
          }
        });

        Promise.all(promises).then(() => {
          this.$store.dispatch("showSuccess", `All meals on ${this.getDay(day)} locked!`);
          this.locking = false;
        });
      }
    },

    unlockByDay(day) {
      if (this.meals?.length) {
        let promises = [];
        this.locking = true;

        this.meals.forEach((meal) => {
          if (meal.day === day) {
            promises.push(
              this.$store.dispatch("mealplans/updateMealField", {
                meal,
                value: false,
                silent: true,
                field: "locked",
              })
            );
          }
        });

        Promise.all(promises).then(() => {
          this.$store.dispatch(
            "showSuccess",
            `All meals on ${this.getDay(day)} unlocked`
          );
          this.locking = false;
        });
      }
    },

    showCalculator(edit = false) {
      console.log(this.$refs.calculatorDialog);
      if (!edit) {
        this.editNewMealplan = false;

        this.$store.commit("goal/setAddNewMealplan", {
          mainMeals: [],
          snacks: 0,
          customTarget: 0,
        });

        this.$store.commit("mealplans/setMealplanName", "");
      } else {
        this.reqWarning = false;
        this.editNewMealplan = true;
        this.$store.commit("mealplans/setMealplanName", this.plan.name);
      }

      this.$refs.calculatorDialog.showDialog(edit);
    },
    closeCalculator() {
      if (!this.editNewMealplan) {
        this.$store.commit("goal/setAddNewMealplan", {
          mainMeals: this.goal.mainMeals,
          snacks: this.goal.snacks,
          customTarget: this.goal.customTarget,
        });
      }

      this.editNewMealplan = false;
    },

    changePlan(p) {
      this.nutritionInfo = {
        weight: 0,
        energy: 0,
        protein: 0,
        carbohydrate: 0,
        sugar: 0,
        fibre: 0,
        sodium: 0,
        fat: 0,
      };
      this.$emit("selected", p, false, this.planSlide);
      this.overlay = true;

      setTimeout(() => {
        this.overlay = false;
      }, 4000);
    },

    confirmDrop(data) {
      this.reqWarning1 = true;
      this.droppedData = data;
    },

    dropped(data) {
      let recipe = this.$store.getters["recipes/recipe"](this.itemDragged.recipe);

      Promise.resolve(
        this.$store.dispatch("mealplans/insertMeal", {
          recipe,
          serves: 1,
          cell: data,
          origin: this.itemDragged.ref,
          originData: { ...this.itemDragged },
        })
      ).then(() => {
        this.$store.dispatch("mealplans/updateMealField", {
          silent: true,
          field: "serves",
          meal: this.itemDragged,
          value: this.itemDragged.serves - 1,
        });

        if (this.plan?.requestStatus == "approved") {
          this.$store.dispatch("mealplans/updateMealField", {
            value: "edited",
            field: "requestStatus",
            meal: this.plan,
            silent: true,
          });
          this.$store.dispatch(
            "showError",
            "Meal Plan has been edited, resubmit it for review"
          );
          this.reqWarning1 = false;
        }
      });
    },

    showShoppingListDialog() {
      this.$refs.shoppingList.showDialog();
    },

    undoDelete(meal, day) {
      let deleted = this.recentlyDeleted.find((m) => m.meal === meal && m.day === day);

      let data = {
        cell: {
          day: day,
          meal: meal,
          plan: this.plan,
        },
        recipe: deleted.data.recipe,
      };

      // this.$store.commit('mealplans/setAllMeals', deleted.data.recipe.id)

      Promise.resolve(this.$store.dispatch("mealplans/insertMeal", data));
    },

    generateRedirectLink(val) {
      return `${window.origin}/redirect?loc=${val}`;
    },
    addFixedDecimals(val) {
      if (val % 1 != 0) return this.$options.filters.amount(val);

      return val;
    },
    mealPlanDownload() {
      this.statuses = [];

      if (!this.$store.getters["subscriptions/subscribed"])
        this.$root.$emit("showSubscribeDialog");
      else {
        if (
          (!this.user.customLogo || !this.user.brandColor) &&
          this.user.role !== "member"
        )
          this.openWarningDialog();
        else this.confirmDownload = true;
      }
    },
    openWarningDialog() {
      this.$refs.warningDialog.openDialog();
    },

    async autoGenerateMethod(plan) {
      if (plan?.ingredientTags?.length) {
        let allIngredientTags = [...plan.ingredientTags];

        this.$store.commit('mealplans/generatingState', true)

        const getTags = new Promise((resolve) => {
          plan.ingredientTags.forEach(async (tag, i, array) => {
            /*
            * TODO: ADD A CHECK TO FOR ERRORS
            */
            const { hits } = await index.search(tag);

            if (hits.length)
              hits.forEach(hit => {
                allIngredientTags.push(hit.name)
              })

            if(i == array.length - 1) resolve()
          });
        })

        getTags.then(() => {
          this.autoGenerate({ plan: {...plan, ingredientTags: allIngredientTags}, roleType: this.roleType });
        })

      } else {
        this.autoGenerate({ plan, roleType: this.roleType });
      }

    },
    removeSpace(text) {
      return text.replace(/\s/g, "");
    },
    addedNewMeal(newData) {
      setTimeout(() => {
        this.$emit("addedNewMeal", newData);
      }, 1500);
    },
    async moveMealplan() {
      const folder = this.allFolders.find((f) => f.id == this.selectedMoveFolder);

      const {
        client,
        age,
        email,
        weight,
        height,
        gender,
        goal,
        physicalActivityLevel,
      } = folder;

      await Promise.resolve(
        this.update({
          age,
          goal,
          email,
          client,
          weight,
          height,
          gender,
          physicalActivityLevel,
        })
      );

      Promise.resolve(
        this.moveToAnotherFolder({
          mealplanId: this.plan.id,
          plan: this.plan,
          folderId: this.selectedMoveFolder,
        })
      ).then(() => {
        this.moveDialog = false;
        this.$store.dispatch("showSuccess", `Mealplan moved successfully!`);
        this.$emit("clearSelectPlan");

        setTimeout(() => {
          this.$emit("addedNewMeal");
        }, 600);
      });
    },

    async duplicateMealplan() {
      this.$store.commit("mealplans/duplicatingState", true);

      const currentPlanId = this.plan.id
      const folder = this.allFolders.find((f) => f.id == this.selectedDuplicateFolder);

      this.$store.commit("goal/setGoalData", {
        ...folder,
        mainMeals: this.goal.mainMeals,
        snacks: this.goal.snacks,
        customTarget: this.goal.customTarget,
      });

      Promise.resolve(
        this.create({
          name: this.duplicateName,
          folderId: folder.id,
          silent: true,
        })
      ).then(({ error, newData }) => {
        if (!error) {
          this.addedNewMeal(newData);

          setTimeout(() => {
            Promise.resolve(this.$store.dispatch("goal/create", newData.id)).then(
              async (response) => {
                this.duplicateDialog = false;

                // this.getGoal(response.id)

                this.addPlanToFolder({ id: newData.id, folder });

                if (this.meals?.length)
                  await this.duplicateMeal({
                    meals: this.meals,
                    plan: newData,
                  });

                this.$store.commit("mealplans/duplicatingState", false);

                if (this.currentFolder.id != folder.id) {
                  this.$store.commit("goal/setGoalData", this.currentFolder);
                  this.$emit("manualSelect", currentPlanId);
                }
              },
              (error) => {
                this.$store.commit("mealplans/duplicatingState", false);
                this.$store.dispatch("showError", error);
                if (this.currentFolder.id != folder.id) {
                  this.$store.commit("goal/setGoalData", this.currentFolder);
                }
              }
            );

            this.$store.dispatch(
              "showSuccess",
              `Mealplan has been duplicated to ${folder.client}`
            );
          }, 500);
        } else {
          console.log(error);
          if (this.currentFolder.id != folder.id) {
            this.$store.commit("goal/setGoalData", this.currentFolder);
          }
        }
      });
    },

    async convertPlan() {

      this.converting = true

      const currentPlanId = this.plan.id
      const folder = this.currentFolder;

      this.$store.commit("goal/setGoalData", {
        ...folder,
        mainMeals: this.goal.mainMeals,
        snacks: this.goal.snacks,
        customTarget: this.goal.customTarget,
      });

      Promise.resolve(
        this.create({
          name: this.plan.name,
          folderId: folder.id,
          silent: true,
          recipeplan: this.isOnMealPlanRoute ? true : false,
        })
      ).then(({ error, newData }) => {
        if (!error) {
          // this.addedNewMeal(newData);

          setTimeout(() => {
            Promise.resolve(this.$store.dispatch("goal/create", newData.id)).then(
              async (response) => {
                this.duplicateDialog = false;

                // this.getGoal(response.id)

                this.addPlanToFolder({ id: newData.id, folder });

                if (this.meals?.length)
                  await this.duplicateMeal({
                    meals: this.meals,
                    plan: newData,
                  });

                this.converting = false

                console.log(currentPlanId)

                // this.$store.commit("goal/setGoalData", this.currentFolder);
                this.$emit("manualSelect", currentPlanId);
              },
              (error) => {
                this.$store.commit("mealplans/duplicatingState", false);
                this.$store.dispatch("showError", error);
                if (this.currentFolder.id != folder.id) {
                  this.$store.commit("goal/setGoalData", this.currentFolder);
                }
              }
            );

            this.$store.dispatch(
              "showSuccess",
              `${this.isOnMealPlanRoute ? 'Meal Plan' : 'Recipe Plan'} has been copied`
            );
          }, 500);
        } else {
          console.log(error);
          if (this.currentFolder.id != folder.id) {
            this.$store.commit("goal/setGoalData", this.currentFolder);
          }
        }
      });
    },
    toggleEnergy() {
      this.$store.dispatch("mealplans/updateMealField", {
        value: this.plan.hideEnergy,
        field: "hideEnergy",
        meal: this.plan,
      });

      if (this.plan?.description || this.plan?.mealplanDescription) {
        this.showEbookUpdate = true;
      }
    },
    toggleMealTags() {
      this.$store.dispatch("mealplans/updateMealField", {
        value: this.plan.hideMealTags,
        field: "hideMealTags",
        meal: this.plan,
      });

      if (this.plan?.description || this.plan?.mealplanDescription) {
        this.showEbookUpdate = true;
      }
    },
    toggleNutriTags() {
      this.$store.dispatch("mealplans/updateMealField", {
        value: this.plan.hideNutriTags,
        field: "hideNutriTags",
        meal: this.plan,
      });

      if (this.plan?.description || this.plan?.mealplanDescription) {
        this.showEbookUpdate = true;
      }
    },

    /**
     * TODO: OPTMIZE THESE FUNCTIONS (requestApproval & requestApprovalAgain)
     */
    requestApproval(removeCookies = false, resubmit = false) {
      this.requesting = true;

      Promise.resolve(
        this.$store.dispatch("mealplans/updateMealField", {
          value: "requesting",
          field: "requestStatus",
          meal: this.plan,
          silent: true,
        })
      )
        .then(() => {
          Promise.resolve(
            this.$store.dispatch("mealplans/updateMealField", {
              value: this.additionalInfo,
              field: "additionalInfo",
              meal: this.plan,
            })
          )
            .then(() => {
              this.requesting = false;
              this.requestDialog = false;
              this.additionalInfo = "";

              if (!resubmit) {
                this.$store.dispatch("user/updateUserField", {
                  value: this.user.requestTokens - 1,
                  field: "requestTokens",
                  silent: true,
                });
              }

              if (removeCookies) {
                this.removeCookies();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    requestApprovalAgain() {
      this.requesting = true;

      Promise.resolve(
        this.$store.dispatch("mealplans/updateMealField", {
          value: "requesting",
          field: "requestStatus",
          meal: this.plan,
          silent: true,
        })
      )
        .then(() => {
          Promise.resolve(
            this.$store.dispatch("mealplans/updateMealField", {
              value: this.plan.additionalInfo,
              field: "additionalInfo",
              meal: this.plan,
            })
          )
            .then(() => {
              this.requesting = false;
              this.requestDialog = false;
              this.additionalInfo = "";

              if (this.plan?.hasEdited) {
                this.$store.dispatch("user/updateUserField", {
                  value: this.user.requestTokens - 1,
                  field: "requestTokens",
                  silent: true,
                });
              } else {
                this.$store.dispatch("mealplans/updateMealField", {
                  value: true,
                  field: "hasEdited",
                  meal: this.plan,
                  silent: true,
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    buyToken(stampProduct) {
      this.buyingToken = true;
      const folder = this.currentFolder.id;
      const mealplan = this.plan.id;

      this.$cookies.set("requestApproval", true);
      this.$cookies.set("requestTokens", stampProduct.stripe_metadata_token);
      this.$cookies.set("requestPlan", this.plan.id);

      this.purchaseToken({
        folder,
        mealplan,
        stampProduct,
        info: this.additionalInfo,
      });
    },

    removeCookies() {
      this.$cookies.remove("requestApproval");
      this.$cookies.remove("requestTokens");
      this.$cookies.remove("requestPlan");

      this.$router.push({ query: {} });
    },

    setRoleType(type) {
      this.$emit("roleType", type);
    },

    openAlternateDialog(meal) {
      this.wordToAlter = meal;
      this.alternateDialog = true;
    },

    confirmAlternate() {
      this.loadingAlter = true;
      Promise.resolve(
        this.$store.dispatch("mealplans/updateMealField", {
          value: this.alternateWords,
          field: "alternateWords",
          meal: this.plan,
          silent: true,
        })
      ).then(() => {
        this.loadingAlter = false;

        this.alternateDialog = false;

        this.$store.dispatch("showSuccess", "Alternate words has been setup!");
      });
    },

    getAlternateWord(val) {
      if (!this.plan?.alternateWords) return "";

      if (this.plan?.alternateWords.hasOwnProperty(val)) {
        return `(${this.plan.alternateWords[val]})`;
      } else return "";
    },

    refreshEbook() {
      this.refreshingEbook = true

      Promise.resolve(this.$store.dispatch('mealplans/updateMealField', {
        value: this.myRecipes,
        field: 'myRecipes',
        meal: this.plan
      })).then(() => {
        this.refreshingEbook = false
      })
    },

    toggleTags() {

      let recipes, user, mainMeals, meals, goal

      if (this.plan.linkDetails) {
        recipes = this.plan.linkDetails.recipes
        user = this.plan.linkDetails.user
        mainMeals = this.plan.linkDetails.mainMeals
        meals = this.plan.linkDetails.meals
        goal = this.plan.linkDetails.goal
      } else {
        user = {
          id: this.user.id,
          role: this.user.role,
          lastName: this.user.lastName,
          firstName: this.user.firstName,
          brandColor: this.user.brandColor,
          energyUnit: this.user.energyUnit,
          isStudent: this.user?.isStudent ? true : false,
          hideEnergy: this.plan.hideEnergy ? this.plan.hideEnergy : "",
          customLogoUrl: this.user.customLogo ? this.user.customLogoUrl : "",
        },

        recipes = [...this.myRecipes]
        mainMeals = this.mainMeals
        meals = this.meals
        goal =  this.goal
      }

      const promoData = this.plan?.linkDetails?.user?.promoData || {}

      const details = {
        user: {
          ...user,
          promoData: {...promoData, ...this.recipePlanTags}
        },
        goal,
        meals,
        recipes,
        mainMeals,
      }

      this.updateRecipeLink({
        plan: this.plan,
        details,
        silent: true
      })
    },
    toggleCustomization(val) {
      this.toggleCustomLoading = true
      Promise.resolve(
        this.$store.dispatch("mealplans/updateMealField", {
          meal: this.plan,
          field: "hideCustomization",
          value: val,
        })
      )
      .then(() => {
        this.$store.dispatch("showSuccess", "Successfully hidden customization button!");
      })
      .finally(() => {
        this.toggleCustomLoading = false
      })
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.plan) this.getMeals(this.plan);

    this.$store.dispatch("foods/getCategories");

    if (this.$route.query.request == "success") {
      if (this.$cookies.get("requestApproval")) {
        this.additionalInfo = this.$route.query.info;
        this.requestDialog = true;
        this.requesting = true;
        let token = this.$cookies.get("requestTokens");
        if (token == 1) {
          this.$store.dispatch("user/updateUserField", {
            value: 1,
            field: "requestTokens",
            silent: true,
          });
        } else {
          this.$store.dispatch("user/updateUserField", {
            value: 10,
            field: "requestTokens",
            silent: true,
          });
        }

        setTimeout(() => {
          this.requestApproval(true);
        }, 1000);
      }
    } else if (this.$route.query.request == "failed") {
      this.additionalInfo = this.$route.query.info;
      this.requestDialog = true;

      setTimeout(() => {
        this.removeCookies();
      }, 2500);
    }

    setTimeout(() => {
      this.overlay = false;
    }, 4000);

    if(this.$route.query?.planSlide) {
      this.planSlide = +this.$route.query?.planSlide
    }
  },
};
</script>

<style lang="scss">
.v-overflow-btn .v-input__slot {
  border-width: 0 !important;
}

.invisible {
  display: none;
}

.mealplan-container {
  position: relative;
}

.mealplan-names {
  position: absolute;
  top: -25px;
  left: 0px;
  background-color: #463f57;
  color: white;
  padding: 0 1rem;
}

.plan-container-info {
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #97c81f;
  }

  &.selected-plan {
    background-color: #97c81f;
  }
}

.plan-container-info.reverse {
  &:hover {
    background-color: #463f57;
  }

  &.selected-plan {
    background-color: #463f57;
  }
}
.plan-icon {
  width: 55px;
  height: 55px;

  &.large {
    width: 100px;
    height: 100px;
  }
}

.plan-info {
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plan-info-name {
  // color: #463f57;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow-container {
  position: relative;
}

.bottom-arrow1:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  bottom: -8%;
  border-top: 25px solid #463f57;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.bottom-arrow1.blue:after {
  border-top: 25px solid #97c81f;
}
.custom-purple {
  background-color: #463f57 !important;
  border-color: #463f57 !important;
}
</style>
